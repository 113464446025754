import React from "react";

export const Calendar = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      id="Capa_1"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
      width="34px"
      height="34px"
    >
      <g>
        <g>
          <g>
            <g>
              <circle
                cx="386"
                cy="210"
                r="20"
                data-original="#000000"
                className="active-path"
                data-old_color="#000000"
                fill="currentColor"
              />
              <path
                d="M432,40h-26V20c0-11.046-8.954-20-20-20c-11.046,0-20,8.954-20,20v20h-91V20c0-11.046-8.954-20-20-20     c-11.046,0-20,8.954-20,20v20h-90V20c0-11.046-8.954-20-20-20s-20,8.954-20,20v20H80C35.888,40,0,75.888,0,120v312     c0,44.112,35.888,80,80,80h153c11.046,0,20-8.954,20-20c0-11.046-8.954-20-20-20H80c-22.056,0-40-17.944-40-40V120     c0-22.056,17.944-40,40-40h25v20c0,11.046,8.954,20,20,20s20-8.954,20-20V80h90v20c0,11.046,8.954,20,20,20s20-8.954,20-20V80h91     v20c0,11.046,8.954,20,20,20c11.046,0,20-8.954,20-20V80h26c22.056,0,40,17.944,40,40v114c0,11.046,8.954,20,20,20     c11.046,0,20-8.954,20-20V120C512,75.888,476.112,40,432,40z"
                data-original="#000000"
                className="active-path"
                data-old_color="#000000"
                fill="currentColor"
              />
              <path
                d="M391,270c-66.72,0-121,54.28-121,121s54.28,121,121,121s121-54.28,121-121S457.72,270,391,270z M391,472     c-44.663,0-81-36.336-81-81s36.337-81,81-81c44.663,0,81,36.336,81,81S435.663,472,391,472z"
                data-original="#000000"
                className="active-path"
                data-old_color="#000000"
                fill="currentColor"
              />
              <path
                d="M420,371h-9v-21c0-11.046-8.954-20-20-20c-11.046,0-20,8.954-20,20v41c0,11.046,8.954,20,20,20h29     c11.046,0,20-8.954,20-20C440,379.954,431.046,371,420,371z"
                data-original="#000000"
                className="active-path"
                data-old_color="#000000"
                fill="currentColor"
              />
              <circle
                cx="299"
                cy="210"
                r="20"
                data-original="#000000"
                className="active-path"
                data-old_color="#000000"
                fill="currentColor"
              />
              <circle
                cx="212"
                cy="297"
                r="20"
                data-original="#000000"
                className="active-path"
                data-old_color="#000000"
                fill="currentColor"
              />
              <circle
                cx="125"
                cy="210"
                r="20"
                data-original="#000000"
                className="active-path"
                data-old_color="#000000"
                fill="currentColor"
              />
              <circle
                cx="125"
                cy="297"
                r="20"
                data-original="#000000"
                className="active-path"
                data-old_color="#000000"
                fill="currentColor"
              />
              <circle
                cx="125"
                cy="384"
                r="20"
                data-original="#000000"
                className="active-path"
                data-old_color="#000000"
                fill="currentColor"
              />
              <circle
                cx="212"
                cy="384"
                r="20"
                data-original="#000000"
                className="active-path"
                data-old_color="#000000"
                fill="currentColor"
              />
              <circle
                cx="212"
                cy="210"
                r="20"
                data-original="#000000"
                className="active-path"
                data-old_color="#000000"
                fill="currentColor"
              />
            </g>
          </g>
        </g>
      </g>{" "}
    </svg>
  );
};
