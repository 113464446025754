import React, { useState, useEffect, SetStateAction, Dispatch } from "react";
import { RootState, AppDispatch } from "@redux/store";
import { AlertTwoTone } from "@ant-design/icons";
import { Textfield, Button, Notification } from "@common";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { InputStatus, InputTypes, Paths, LoadingStatus, ResetPassErrors } from "@types";
import { validatePass } from "@utils/functions";
import { approveNewPassword } from "@redux/modules/auth";

import classes from "./style.module.scss";

interface Props {
  setStepNumber: Dispatch<SetStateAction<number>>;
}

enum NewAuth {
  Pass = "pass",
  PassRepeat = "passRepeat",
  Code = "code",
}

const initialNewAuth = {
  [NewAuth.Code]: "",
  [NewAuth.Pass]: "",
  [NewAuth.PassRepeat]: "",
};

const initialNewAuthValidate = {
  [NewAuth.Code]: false,
  [NewAuth.Pass]: false,
  [NewAuth.PassRepeat]: false,
};

export const ApproveCode: React.FC<Props> = ({ setStepNumber }) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [newAuth, setNewAuth] = useState(initialNewAuth);
  const [isTouch, setTouched] = useState(false);
  const [newAuthValidate, setNewAuthValidate] = useState(initialNewAuthValidate);
  const [notification, setNotification] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const email = useSelector((state: RootState) => state.auth.forgotEmail);
  const approveNewPasswordStatus = useSelector((state: RootState) => state.auth.approveNewPasswordStatus);
  const resetPasswordMessage = useSelector((state: RootState) => state.auth.approvePasswordMessage);

  const validateFields = (value: string, key: NewAuth) => {
    switch (key) {
      case NewAuth.Pass:
        return validatePass(value);
      case NewAuth.PassRepeat:
        return value === newAuth[NewAuth.Pass];
      case NewAuth.Code:
        return value.length > 3;
    }
  };

  const handleChange = (e, key: NewAuth) => {
    const value = e.target.value;
    setNewAuth({
      ...newAuth,
      [key]: value,
    });

    setNewAuthValidate({
      ...newAuthValidate,
      [key]: validateFields(value, key),
    });
  };

  useEffect(() => {
    if (approveNewPasswordStatus === LoadingStatus.Success) {
      setStepNumber(2);
    }
    if (approveNewPasswordStatus === LoadingStatus.Error) {
      setNotification(true);
      setErrorMessage(ResetPassErrors.InvalidCode);
      if (resetPasswordMessage === ResetPassErrors.AttemptsExhausted) {
        setStepNumber(0);
        navigate(Paths.Root);
      }
    }
  }, [approveNewPasswordStatus, resetPasswordMessage]);

  const handleConfirm = () => {
    setTouched(true);

    if (
      !newAuthValidate[NewAuth.Pass] ||
      !newAuthValidate[NewAuth.PassRepeat] ||
      newAuthValidate[NewAuth.Pass] !== newAuthValidate[NewAuth.PassRepeat]
    ) {
      setNotification(true);
      setErrorMessage(ResetPassErrors.InvalidPass);
    }

    if (newAuthValidate[NewAuth.Code] && newAuthValidate[NewAuth.Pass] && newAuthValidate[NewAuth.PassRepeat]) {
      dispatch(
        approveNewPassword({
          code: newAuth[NewAuth.Code],
          password: newAuth[NewAuth.Pass],
          email,
        })
      );
    }
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <h2 className={classes.title}>Подтвердите адрес</h2>
        <p className={classes.description}>
          На адрес {email} отправлен четырёхзначный код активации – пожалуйста, введите его здесь вместе с новым паролем
          чтобы изменить авторизационные данные
        </p>
        <div className={classes.input}>
          <Textfield
            value={newAuth[NewAuth.Code]}
            keyId={NewAuth.Code}
            handleChange={handleChange}
            placeholder="Код"
            maxLength={4}
            status={isTouch && !newAuthValidate[NewAuth.Code] ? InputStatus.Error : InputStatus.Success}
          />
        </div>

        <div className={classes.input}>
          <Textfield
            value={newAuth[NewAuth.Pass]}
            type={InputTypes.Pass}
            keyId={NewAuth.Pass}
            handleChange={handleChange}
            placeholder="Пароль"
            status={isTouch && !newAuthValidate[NewAuth.Pass] ? InputStatus.Error : InputStatus.Success}
          />
        </div>

        <div className={classes.input}>
          <Textfield
            value={newAuth[NewAuth.PassRepeat]}
            keyId={NewAuth.PassRepeat}
            type={InputTypes.Pass}
            handleChange={handleChange}
            placeholder="Повторите пароль"
            status={isTouch && !newAuthValidate[NewAuth.PassRepeat] ? InputStatus.Error : InputStatus.Success}
          />
        </div>
        <div className={classes.button}>
          <Button handleClick={handleConfirm} text={"Изменить"} disabled={!newAuthValidate[NewAuth.Code]} />
        </div>
        <Notification
          title={ResetPassErrors.Title}
          message={errorMessage}
          isOpen={notification}
          icon={<AlertTwoTone twoToneColor="#eb2f96" />}
          onClose={() => setNotification(false)}
          duration={1}
        />
      </div>
    </div>
  );
};
