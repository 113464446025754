import React from "react";
import classes from "./style.module.scss";

export const Disclaimer = () => {
  return (
    <p className={classes.authDisclaimer}>
      Используя приложение, Вы принимаете нашу
      <br />
      <a href="https://auth.biodata.group/documents/PersonalDataProcessingPolicy_biodata.pdf" target="_blank">
        Политику в отношении обработки персональных данных
      </a>{" "}
      и{" "}
      <a href="https://auth.biodata.group/documents/User_Agreement_biodata.pdf" target="_blank">
        Пользовательское соглашение
      </a>
    </p>
  );
};
