import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import cn from "classnames";
import FacebookLogin from "react-facebook-login";
import { useDispatch, useSelector } from "react-redux";
import { AlertTwoTone } from "@ant-design/icons";

import { Notification } from "@common";
import { loginSocial, registerSocial, setAppleRegisterToken, setFacebookRegisterToken } from "@redux/modules/auth";
import { Facebook } from "@icons";
import { AppDispatch, RootState } from "@redux/store";
import { ErrorMessages, FacebookTypes, Tabs } from "./types";
import { LoadingStatus, SocialLoginTypes, StepperType } from "@types";

import classes from "./style.module.scss";
import { deleteSocialBind, setSocialBind } from "@redux/modules/profile";

interface Props {
  type: FacebookTypes;
  isBind?: boolean;
  isDisable?: boolean;
  bindStatus?: boolean;
}

export const FacebookBlock: React.FC<Props> = ({ type, isBind, isDisable, bindStatus }) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const userToken = localStorage.getItem("biodata_key") as string;
  const loginSocialStatus = useSelector((state: RootState) => state.auth.loginSocialStatus);
  const registerSocialStatus = useSelector((state: RootState) => state.auth.registerSocialStatus);
  const [notification, setNotification] = useState(false);

  const responseFacebook = (response) => {
    if (response.accessToken) {
      if (type === FacebookTypes.Login) {
        dispatch(
          loginSocial({
            socialName: SocialLoginTypes.Facebook,
            socialToken: response.accessToken,
          })
        );
      } else if (type === FacebookTypes.Register) {
        dispatch(
          registerSocial({
            socialName: SocialLoginTypes.Facebook,
            socialToken: response.accessToken,
          })
        );
        dispatch(setFacebookRegisterToken(response.accessToken));
      } else if (type === FacebookTypes.DeleteBind) {
        dispatch(
          deleteSocialBind({
            socialName: SocialLoginTypes.Facebook,
            userToken: userToken,
          })
        );
      } else {
        dispatch(
          setSocialBind({
            socialName: SocialLoginTypes.Facebook,
            socialToken: response.accessToken,
            userToken: userToken,
          })
        );
      }
    }
  };

  useEffect(() => {
    if (registerSocialStatus === LoadingStatus.Error) {
      setNotification(true);
    }
    if (registerSocialStatus === LoadingStatus.Success) {
      navigate("/register/quiz", { state: { type: StepperType.FacebookRegister } });
    }
  }, [registerSocialStatus]);

  useEffect(() => {
    if (loginSocialStatus === LoadingStatus.Success) {
      window.location.href = `${process.env.REACT_APP_OLD}/profile/index`;
    }
  }, [loginSocialStatus]);

  return (
    <div
      className={cn(
        type === FacebookTypes.Login || FacebookTypes.Register
          ? classes.authSocialFacebook
          : classes.authSocialFacebookBind,
        isDisable && classes.authSocialFacebookDisabled
      )}
    >
      <FacebookLogin
        appId="1110756609336210"
        fields="name,email,picture"
        textButton="Facebook"
        cssClass={classes.facebookButton}
        callback={responseFacebook}
        icon={<Facebook />}
        isDisabled={isDisable}
      />
      {isBind && !isDisable && !bindStatus && <a className={classes.socialBindLabel}>Подключить</a>}
      {isBind && !isDisable && bindStatus && <a className={classes.socialBindLabelRed}>Отключить</a>}
      <Notification
        title={ErrorMessages.Title}
        message={ErrorMessages.AlreadyExist}
        isOpen={notification}
        icon={<AlertTwoTone twoToneColor="#eb2f96" />}
        onClose={() => setNotification(false)}
        duration={1}
      />
    </div>
  );
};
