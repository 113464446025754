import React from "react";
import { Link } from "react-router-dom";
import { Textfield, Checkbox, Button } from "@common";
import { FacebookBlock } from "./FacebookBlock";
import { AppleBlock, Redirects } from "./AppleBlock";
import { InputTypes, InputStatus } from "@types";
import { AuthInput, FacebookTypes } from "./types";
import classes from "./style.module.scss";

export const RegisterTab = ({
  auth,
  handleChange,
  authTouched,
  authValidate,
  handleRegister,
  isApproveAgreement,
  setAgreement,
}) => {
  return (    
    <>
      <h3 className={classes.authSocTitle}>С помощью:</h3>
      <FacebookBlock type={FacebookTypes.Register} />
      <AppleBlock type={Redirects.Register} />
      <h3 className={classes.authSocTitle}>По адресу эл. почты и паролю:</h3>
      <div className={classes.authInput}>
        <Textfield
          handleChange={handleChange}
          placeholder="Email"
          value={auth[AuthInput.Email]}
          keyId={AuthInput.Email}
          type={InputTypes.Text}
          status={authTouched && !authValidate[AuthInput.Email] ? InputStatus.Error : InputStatus.Success}
        />
      </div>
      <div className={classes.authInput}>
        <Textfield
          handleChange={handleChange}
          placeholder="Пароль"
          value={auth[AuthInput.Pass]}
          keyId={AuthInput.Pass}
          type={InputTypes.Pass}
          status={authTouched && !authValidate[AuthInput.Pass] ? InputStatus.Error : InputStatus.Success}
        />
      </div>

      <div className={classes.agreement}>
        <Checkbox handleChange={() => setAgreement(!isApproveAgreement)} checked={isApproveAgreement} />
        <span>
          Согласен с <Link to={"/"}>правилами обработки персональных данных</Link> и{" "}
          <Link to={"/"}>Пользовательским соглашением</Link>
        </span>
      </div>

      <Button
        handleClick={handleRegister}
        text="Создать аккаунт"
        disabled={!auth[AuthInput.Email].length || !auth[AuthInput.Pass].length || !isApproveAgreement}
      />
    </>
  );
};
