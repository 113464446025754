import React, { useState } from "react";
import { ForgotPasswordForm } from "@components/ForgotPasswordSteps/ForgotPasswordForm";
import { ApproveCode } from "@components/ForgotPasswordSteps/ApproveCode";
import { SuccessChange } from "@components/ForgotPasswordSteps/SuccessChange";

export const ForgotPassword = () => {
  const [step, setStep] = useState(0);

  switch (step) {
    case 0:
      return <ForgotPasswordForm setStepNumber={setStep} />;
    case 1:
      return <ApproveCode setStepNumber={setStep} />;
    case 2:
      return <SuccessChange setStepNumber={setStep} />;
    default:
      return <ForgotPasswordForm setStepNumber={setStep} />;
  }
};
