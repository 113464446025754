import React from "react";
import cn from "classnames";
import classes from "./StrokeButton.module.scss";

interface Props {
  text: string;
  handleClick: () => void;
  disabled?: boolean;
  className?: string;
}

export const StrokeButton: React.FC<Props> = ({ text, disabled, handleClick, className }) => {
  return (
    <a
      role="button"
      onClick={!disabled ? handleClick : () => {}}
      className={cn(className, classes.strokeButton, disabled && classes.disabled)}
    >
      {text}
    </a>
  );
};
