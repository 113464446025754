import React, { useEffect, useState, ChangeEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { AlertTwoTone } from "@ant-design/icons";
import cn from "classnames";
import {
  Textfield,
  Datepicker,
  RadioButton,
  Button,
  CalendarTypes,
  StrokeButton,
  Notification,
  Loader,
  LoaderTypes,
} from "@common";

import { initialBinds, IUserBinds, ProfileFields, initialProfile, GenderTypes } from "./types";

import { checkSocialBind, clearChangeEmalStatus, getUserProfile, saveUserProfile } from "@redux/modules/profile";
import { AppDispatch, RootState } from "@redux/store";
import { useAuth } from "@utils/hooks";
import { AppleBlock, Redirects } from "@components/Auth/AppleBlock";
import { calculateBinds, mapSaveProfile } from "./utils";
import { MainMenu } from "@components/MainMenu/MainMenu";

import { Hamburger } from "@assets/icons";

import { FacebookBlock } from "@components/Auth/FacebookBlock";
import { FacebookTypes, ErrorMessages } from "@components/Auth/types";
import { LoadingStatus, Paths, SocialLoginTypes } from "@types";

import { Moment } from "moment";
import classes from "./style.module.scss";
import { useNavigate } from "react-router-dom";
import { clearConfirmStatus } from "@redux/modules/auth";
import { TopMenu } from "@components/TopMenu/TopMenu";

// const DropdownIcon = ({ isOpen }) => {
//   return <DownOutlined className={cn(classes.dropdownIcon, isOpen && classes.dropdownIconRotate)} />;
// };

// const TopMenuDropdown = ({ fullName, type, email }) => {
//   const [isVisible, setVisible] = useState(false);
//   return (
//     <Dropdown
//       overlayClassName={classes.dropdownPanel}
//       overlay={<DropdownMenu email={email} name={fullName} role={mapUserType(type)} />}
//       trigger={["click"]}
//       onVisibleChange={() => setVisible(!isVisible)}
//       placement="bottomRight"
//       align={{ offset: [19, 16] }}
//     >
//       <div className={classes.dropdownButton}>
//         <DropdownIcon isOpen={isVisible} />
//       </div>
//     </Dropdown>
//   );
// };

export const UserBinds: React.FC<IUserBinds> = ({ bindsStatus }) => {
  return (
    <div className={classes.settings}>
      <h2>Привязка к сервисам</h2>
      <AppleBlock
        isBind
        bindStatus={bindsStatus[SocialLoginTypes.Apple]}
        type={bindsStatus[SocialLoginTypes.Apple] ? Redirects.DeleteBind : Redirects.SetBind}
      />
      <FacebookBlock
        isBind
        bindStatus={bindsStatus[SocialLoginTypes.Facebook]}
        type={bindsStatus[SocialLoginTypes.Facebook] ? FacebookTypes.DeleteBind : FacebookTypes.SetBind}
      />
    </div>
  );
};

export const UserCredentials = ({ email, handleChange, isConfirmed }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChangeEmail = () => {
    dispatch(clearChangeEmalStatus());
    dispatch(clearConfirmStatus());
    navigate(Paths.ChangeEmail);
  };
  return (
    <div className={classes.credentials}>
      <h2>Логин</h2>
      <Textfield
        disabled={true}
        placeholder="Email"
        value={email}
        handleChange={handleChange}
        keyId={ProfileFields.Email}
      />

      {isConfirmed && (
        <StrokeButton
          text={"Сменить пароль"}
          handleClick={() => {
            navigate(Paths.ForgotPassword);
          }}
          className={classes.changePassButton}
        />
      )}

      {isConfirmed && (
        <StrokeButton text={"Сменить email"} handleClick={handleChangeEmail} className={classes.changePassButton} />
      )}
    </div>
  );
};

export const UserData = ({
  name,
  surname,
  month,
  year,
  gender,
  handleChange,
  handleChangeDate,
  handleChecked,
  dataset,
}) => {
  return (
    <div className={classes.profile}>
      <h2>Личные данные</h2>

      <div className={classes.twoInputs}>
        <Textfield placeholder="Имя" value={name} handleChange={handleChange} keyId={ProfileFields.Name} />
        <Textfield placeholder="Фамилия" value={surname} handleChange={handleChange} keyId={ProfileFields.Surname} />
      </div>

      <div className={classes.twoInputs}>
        <Datepicker
          placeholder="Месяц рождения"
          handleChange={handleChangeDate}
          keyId={ProfileFields.BirthMonth}
          picker={CalendarTypes.Month}
          format={"MMMM"}
          value={moment(month, "MM")}
          allowClear={false}
        />
        <Datepicker
          placeholder="Год рождения"
          handleChange={handleChangeDate}
          keyId={ProfileFields.BirthYear}
          picker={CalendarTypes.Year}
          value={moment(year, "YYYY")}
          allowClear={false}
        />
      </div>

      <div className={classes.selectSex}>
        <RadioButton handleChange={() => handleChecked(GenderTypes.Male)} checked={gender === 1}>
          <span>Мужской</span>
        </RadioButton>
        <RadioButton handleChange={() => handleChecked(GenderTypes.Female)} checked={gender === 2}>
          <span>Женский</span>
        </RadioButton>
      </div>
    </div>
  );
};

export const HamburgerButton = ({ handleClick }) => {
  return (
    <div onClick={handleClick} className={classes.hamburgerButton}>
      <Hamburger />
    </div>
  );
};

export const ProfileLayout = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { isAuth, key } = useAuth();
  const [isTouch, setTouch] = useState(false);
  const [bindsStatus, setBindStatus] = useState(initialBinds);
  const [notification, setNotification] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>(ErrorMessages.Common);

  const setSocialBindStatus = useSelector((state: RootState) => state.profile.setSocialBindStatus);
  const deleteSocialBind = useSelector((state: RootState) => state.profile.deleteSocialBindStatus);
  const deleteSocialBindMessage = useSelector((state: RootState) => state.profile.deleteSocialBindErrorMessage);
  const checkBindStatus = useSelector((state: RootState) => state.profile.checkBindStatus);
  const checkBindList = useSelector((state: RootState) => state.profile.checkBind);
  const userProfile = useSelector((state: RootState) => state.profile.userProfile);
  const getUserStatus = useSelector((state: RootState) => state.profile.getUserProfileStatus);
  const saveFormStatus = useSelector((state: RootState) => state.profile.saveUserProfileStatus);

  const [fields, setFields] = useState(initialProfile);

  useEffect(() => {
    if (key) {
      dispatch(getUserProfile(key));
    }
  }, [key, saveFormStatus]);

  useEffect(() => {
    if (getUserStatus === LoadingStatus.Success) {
      const { firstName, email, lastName, gender, birthdayString, type } = userProfile;
      setFields({
        ...fields,
        [ProfileFields.Name]: firstName,
        [ProfileFields.Email]: email,
        [ProfileFields.BirthMonth]: moment(birthdayString, "YYYY-MM-DD").format("MM"),
        [ProfileFields.BirthYear]: moment(birthdayString, "YYYY-MM-DD").format("YYYY"),
        [ProfileFields.Surname]: lastName,
        [ProfileFields.Gender]: gender,
        [ProfileFields.Type]: type,
      });
    }
  }, [getUserStatus]);

  const handleChecked = (gender: GenderTypes) => {
    setTouch(true);
    setFields({
      ...fields,
      [ProfileFields.Gender]: gender === GenderTypes.Male ? 1 : 2,
    });
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>, keyId: ProfileFields) => {
    const value = e.target.value;
    setTouch(true);
    setFields({
      ...fields,
      [keyId]: value,
    });
  };

  const handleChangeDate = (date: Moment, dateString: string, keyId: ProfileFields) => {
    setTouch(true);
    if (keyId === ProfileFields.BirthMonth) {
      setFields({
        ...fields,
        [keyId]: date ? moment(date).format("MM") : moment(fields[ProfileFields.BirthMonth]).format("MM"),
      });
    }

    if (keyId === ProfileFields.BirthYear) {
      setFields({
        ...fields,
        [keyId]: date ? moment(date).format("YYYY") : moment(fields[ProfileFields.BirthYear]).format("YYYY"),
      });
    }
  };

  const handleSaveForm = () => {
    if (key) {
      dispatch(
        saveUserProfile({
          data: mapSaveProfile(fields),
          token: key,
        })
      );
    }
  };

  useEffect(() => {
    if (checkBindStatus === LoadingStatus.Success) {
      setBindStatus(calculateBinds(checkBindList));
    }
  }, [checkBindStatus]);

  useEffect(() => {
    if (key || setSocialBindStatus === LoadingStatus.Success || deleteSocialBind === LoadingStatus.Success) {
      dispatch(checkSocialBind(key));
    }
  }, [key, setSocialBindStatus, deleteSocialBind]);

  useEffect(() => {
    if (deleteSocialBind === LoadingStatus.Error && deleteSocialBindMessage) {
      setNotification(true);
      setErrorMessage(ErrorMessages.FinalBind);
    }
  }, [deleteSocialBind, deleteSocialBindMessage]);

  useEffect(() => {
    if (setSocialBindStatus === LoadingStatus.Error) {
      setNotification(true);
      setErrorMessage(ErrorMessages.BindAlreadyExist);
    }
  }, [setSocialBindStatus]);

  const isLoading =
    setSocialBindStatus === LoadingStatus.Pending ||
    deleteSocialBind === LoadingStatus.Pending ||
    checkBindStatus === LoadingStatus.Pending ||
    saveFormStatus === LoadingStatus.Pending;

  return (
    <>
      {isLoading ? (
        <Loader type={LoaderTypes.Blue} />
      ) : (
        <div className={classes.wrapper}>
          <MainMenu />
          <div className={classes.main}>
            <TopMenu />
            {/* <div className={classes.topMenu}>
              <TopMenuDropdown
                fullName={`${userProfile?.firstName} ${userProfile?.lastName}`}
                type={fields[ProfileFields.Type]}
                email={userProfile?.email}
              />
              {/* <CheckoutButton handleCheckout={() => {}} /> */}
            {/* </div>  */}

            <div className={cn(classes.content, userProfile?.dataset === "demo" && classes.demoContent)}>
              {userProfile?.dataset !== "demo" && (
                <UserData
                  name={fields[ProfileFields.Name]}
                  surname={fields[ProfileFields.Surname]}
                  month={fields[ProfileFields.BirthMonth]}
                  year={fields[ProfileFields.BirthYear]}
                  gender={fields[ProfileFields.Gender]}
                  handleChange={handleChange}
                  handleChangeDate={handleChangeDate}
                  handleChecked={handleChecked}
                  dataset={userProfile?.dataset}
                />
              )}
              <UserCredentials
                handleChange={handleChange}
                email={fields[ProfileFields.Email]}
                isConfirmed={userProfile?.emailConfirmed}
              />
              <UserBinds bindsStatus={bindsStatus} />
            </div>
            <Button
              text={"Сохранить"}
              handleClick={handleSaveForm}
              disabled={!isTouch}
              className={classes.profileSave}
            />
          </div>
        </div>
      )}
      <Notification
        title={ErrorMessages.Title}
        message={errorMessage}
        isOpen={notification}
        icon={<AlertTwoTone twoToneColor="#eb2f96" />}
        onClose={() => setNotification(false)}
        duration={10}
      />
    </>
  );
};
