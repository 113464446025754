import React from "react";
import { Spin } from "antd";
import cn from "classnames";
import classes from "./Loader.module.scss";

interface Props {
  type?: LoaderTypes;
}

export enum LoaderTypes {
  White = "White",
  Blue = "Blue",
}
export const Loader: React.FC<Props> = ({ type }) => {
  return (
    <div className={cn(classes.loader, type === LoaderTypes.Blue && classes.blue)}>
      <Spin size="large" />
    </div>
  );
};
