import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { LoadingStatus, SocialLoginTypes, StepperType } from "@types";
import { registerSocial, setAppleRegisterToken } from "@redux/modules/auth/slice";
import { AppDispatch, RootState } from "@redux/store";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export const AppleSuccessRegister = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get("data");

  const registerSocialStatus = useSelector((state: RootState) => state.auth.registerSocialStatus);

  useEffect(() => {
    if (token) {
      dispatch(
        registerSocial({
          socialName: SocialLoginTypes.Apple,
          socialToken: window.atob(token),
        })
      );
      dispatch(setAppleRegisterToken(window.atob(token)))
    }
  }, [token]);

  useEffect(() => {
    if (registerSocialStatus === LoadingStatus.Success) {
      navigate("/register/quiz", { state: { type: StepperType.AppleRegister } });
    }
    if (registerSocialStatus === LoadingStatus.Error) {
      navigate("/apple/error");
    }
  }, [registerSocialStatus]);

  return <></>;
};
