import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Textfield, Button } from "@common";
import { validateEmail } from "@utils/functions";
import { RootState } from "@redux/store";
import { resetPasswordRequest, setEmail as setEmailStore } from "@redux/modules/auth/slice";
import classes from "./style.module.scss";
import { AppDispatch } from "@redux/store";
import { LoadingStatus } from "@types";

interface Props {
  setStepNumber: Dispatch<SetStateAction<number>>;
}

export const ForgotPasswordForm: React.FC<Props> = ({ setStepNumber }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [email, setEmail] = useState("");

  const resetPasswordRequestStatus = useSelector((state: RootState) => state.auth.resetPasswordRequestStatus);

  useEffect(() => {
    if (resetPasswordRequestStatus === LoadingStatus.Success) {
      dispatch(setEmailStore({ email }));
      setStepNumber(1);
    }
  }, [resetPasswordRequestStatus]);

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleResetPass = () => {
    dispatch(resetPasswordRequest({ email }));
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <h2 className={classes.title}>Изменение пароля</h2>
        <p>Введите свой адрес эл. почты. Мы сообщим, как вы можете изменить пароль</p>
        <div className={classes.passBlock}>
          <Textfield
            value={email}
            handleChange={handleChangeEmail}
            className={classes.input}
            placeholder="example@mail.ru"
          />
          <Button
            handleClick={handleResetPass}
            text={"Подтвердить"}
            disabled={!validateEmail(email) || resetPasswordRequestStatus === LoadingStatus.Pending}
          />
        </div>
      </div>
    </div>
  );
};
