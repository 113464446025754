export enum Routes {
  // Auth
  Login = "auth/login",
  CreateSession = "auth/create-session",
  Register = "auth/sign-up",
  LoginSocial = "auth-social/login",
  RegisterSocial = "auth-social/register",
  SendEmailConfirmCode = "email-confirmation/confirm-current-mail", // TODO: очистить body так как эта ручка работает без него
  EmailConfirm = "email-confirmation/confirm",
  EditUser = "user/edit",
  FetchGoals = "risk/get-target-templates",
  EditGoals = "risk/create-from-template",
  ResetPasswordRequest = "auth/password-reset-request",
  ResetPasswordApprove = "auth/password-reset",
  // Profile
  CheckSocialBind = "auth-social/check-bind",
  SetBind = "auth-social/set-bind",
  DeleteBind = "auth-social/delete-bind",
  UserProfile = "user/user-profile",
  SaveUserProfile = "user/edit",
  ChangeEmail = "email-confirmation/change-mail",
  AttachEmail = "email-confirmation/attach-mail",
  ConfirmCurrentEmail = "email-confirmation/confirm-current-mail",
  ConfirmNewEmail = "/email-confirmation​/confirm",
  Demo = "user-files/create-file",
  CreateDemoAccount = "auth/create-demo-account",
  CheckGoalsAlreadyExist = 'risk/get-list?isActive=1&isOnboarding=true'
}

export interface Login {
  username: string;
  password: string;
  isOriginalLogin?: boolean;
}

export interface LoginSocial {
  socialName: string;
  socialToken: string;
}

export interface Register {
  email: string;
  password: string;
  sex?: number;
  last_name?: string;
  first_name?: string;
  middle_name?: string;
  phone?: string;
  birthday_date?: number;
}

export interface User {
  result?: boolean;
  auth_key: string;
  birthday_date: number;
  default_marker_tree: number;
  email: string;
  email_confirmed?: boolean;
  register_type?: string;
  first_name: string;
  fullName: string;
  last_name: string;
  middle_name: string;
  phone: string;
  sex: number;
  type: number;
  session_id: string;
  session_name: string;
}

export interface ConfirmResult {
  message: string;
  status: boolean;
}
export interface SendEmailToConfirm {
  email: string;
  token: string;
}

export interface ConfirmEmailByCode {
  code: string;
  token: string;
}

export interface UserInfo {
  first_name: string;
  last_name?: string;
  middle_name?: string;
  birthday_date?: number;
  email?: string;
  phone?: string;
  sex?: number;
  notifications?: any;
}

export interface ApproveNewPassword {
  code: string;
  password: string;
  email: string;
}
