import { RootState } from "@redux/store";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setAuthToken } from "@redux/modules/auth/slice";
import { LoadingStatus } from "@types";

export const useAuth = () => {
  const [status, setStatus] = useState(LoadingStatus.None);
  const [isAuth, setAuth] = useState(false);
  const [key, setKey] = useState("");
  const dispatch = useDispatch();
  const auth_key = useSelector((state: RootState) => state.auth.user?.auth_key);

  const getAuthParams = async () => {
    const localKey = localStorage.getItem("biodata_key");
    setStatus(LoadingStatus.Pending);
    if (localKey) {
      setAuth(true);
      setKey(localKey);

      if (!auth_key) {
        dispatch(setAuthToken({ localKey }));
      }
    }
    setStatus(LoadingStatus.Success);
    return () => {
      setStatus(LoadingStatus.None);
    };
  };

  useEffect(() => {
    getAuthParams();
  }, []);

  return {
    isAuth,
    key,
    status,
  };
};
