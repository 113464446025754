import React from "react";
import { Button } from "@common";
import { ArrowBottom } from "@assets/icons";
import { Select } from "antd";
import photo from "./photo.png";
import classes from "./style.module.scss";

const { Option } = Select;

export const Employees = () => {
  return (
    <div className={classes.employees}>
      <div className={classes.worker}>
        <div className={classes.photo}>
          <img alt="emloyee" src={photo} />
        </div>
        <h3>Перлин Петр</h3>
        <span>Самый здоровый сотрудник</span>
        <div className={classes.indicators}>
          <div className={classes.indicatorItem}>
            <h4>100%</h4>
            <span>активность</span>
          </div>
          <div className={classes.indicatorItem}>
            <h4>75%</h4>
            <span>питание</span>
          </div>
          <div className={classes.indicatorItem}>
            <h4>87%</h4>
            <span>сон</span>
          </div>
        </div>

        <Button className={classes.praise} text="Похвалить" handleClick={() => {}} />
      </div>

      <div className={classes.department}>
        <div className={classes.departmentHeader}>
          <Select defaultValue="месяц" style={{ width: 120 }} bordered={false} suffixIcon={<ArrowBottom />}>
            <Option value="январь">январь</Option>
            <Option value="февраль">февраль</Option>
            <Option value="март">март</Option>
          </Select>
        </div>
        <div>
          <h3>Отдел бухгалтерии</h3>
          <span>Самый Здоровый отдел</span>
          <div className={classes.indicators}>
            <div className={classes.indicatorItem}>
              <h4>100%</h4>
              <span>активность</span>
            </div>
            <div className={classes.indicatorItem}>
              <h4>75%</h4>
              <span>питание</span>
            </div>
            <div className={classes.indicatorItem}>
              <h4>87%</h4>
              <span>сон</span>
            </div>
          </div>
        </div>
        <Button className={classes.praise} text="Похвалить" handleClick={() => {}} />
      </div>
    </div>
  );
};
