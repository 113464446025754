import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setAuthToken } from "@redux/modules/auth/slice";
import { getUserProfile } from "@redux/modules/profile";
import { AppDispatch, RootState } from "@redux/store";
import { getCookie } from "@utils/functions";
import { Paths } from "@types";

export const PrivateRoute = ({ children }) => {
  const dispatch = useDispatch<AppDispatch>();
  const auth_key = useSelector((state: RootState) => state.auth.user?.auth_key);

  const localKey = localStorage.getItem("biodata_key");
  const cookie = getCookie("application-frontend");

  useEffect(() => {
    const localKey = localStorage.getItem("biodata_key");
    dispatch(setAuthToken({ localKey }));
  }, []);

  return localKey ? children : <Navigate to={Paths.Root} />;
};
