import React from "react";

export const Goal = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="10.5" stroke="#7B53AD" stroke-width="3" />
      <circle cx="20" cy="20" r="5.5" stroke="#7B53AD" stroke-width="3" />
      <path d="M18 7C18 5.89543 18.8954 5 20 5C21.1046 5 22 5.89543 22 7V11H18V7Z" fill="#7B53AD" />
      <path d="M22 33C22 34.1046 21.1046 35 20 35C18.8954 35 18 34.1046 18 33L18 29L22 29L22 33Z" fill="#7B53AD" />
      <path d="M33 18C34.1046 18 35 18.8954 35 20C35 21.1046 34.1046 22 33 22L29 22L29 18L33 18Z" fill="#7B53AD" />
      <path d="M7 22C5.89543 22 5 21.1046 5 20C5 18.8954 5.89543 18 7 18L11 18L11 22L7 22Z" fill="#7B53AD" />
      <circle cx="20" cy="20" r="2" fill="#7B53AD" />
    </svg>
  );
};
