import React from "react";
import classes from "./Button.module.scss";
import cn from "classnames";

interface Props {
  text: string;
  handleClick: () => void;
  disabled?: boolean;
  className?: string;
}

export const Button: React.FC<Props> = ({
  text,
  handleClick,
  disabled,
  className,
}) => {
  return (
    <button
      onClick={handleClick}
      disabled={disabled}
      className={cn(classes.button, disabled && classes.disabled, className)}
    >
      {text}
    </button>
  );
};
