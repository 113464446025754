import React from "react";
import classes from "./style.module.scss";

const events = [
  {
    id: 0,
    date: "07. 09. 22",
    time: "12:00 Семинар",
    name: "Вкусные и здоровые завтраки",
  },
  {
    id: 1,
    date: "14. 09. 22",
    time: "15:30 Лекция",
    name: "Комфортные условия для сна",
  },
  {
    id: 2,
    date: "28. 09. 22.",
    time: "12:00 Лекция",
    name: "Необходимость активности",
  },
];

export const Schedule = () => {
  return (
    <div className={classes.schedule}>
      <h3>График Мероприятий</h3>

      {events.map((item) => {
        return (
          <div className={classes.event} key={item.id + item.name}>
            <div className={classes.eventType}>
              <h4>{item.date}</h4>
              <span>{item.time}</span>
            </div>
            <span>{item.name}</span>
          </div>
        );
      })}
    </div>
  );
};
