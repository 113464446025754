import React from "react";

export const Activity = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" fill="#DBF0FF" />
      <path
        d="M20.5237 26.7267C18.763 25.6419 17.1597 24.5212 16.4148 23.9403C15.8113 23.4696 14.5638 22.6988 13.2426 21.8821C11.3225 20.6964 9.14555 19.352 8.15831 18.4457C6.37039 16.8032 7.18036 14.9701 7.19895 14.8891C7.19895 14.8891 11.1951 13.5055 11.4135 13.5719L32.8267 26.9691L32.9801 27.5347C32.8852 28.3347 32.6435 28.9767 31.9836 29.3446C30.1385 30.3723 27.7219 29.9388 26.1086 29.4959C24.4846 29.0504 22.1756 27.7439 20.5237 26.7267C20.5244 26.7267 20.5244 26.7267 20.5237 26.7267Z"
        fill="url(#paint0_linear_1647_30924)"
      />
      <path
        d="M10.0402 13.1829C10.0402 13.1829 9.54621 13.0328 9.37757 13.2068C9.20894 13.3807 7.1986 14.8891 7.1986 14.8891C7.1986 14.8891 7.04125 16.3969 8.67714 17.8987C10.313 19.4004 15.1623 22.4505 16.6627 23.6223C18.1632 24.7941 23.4161 28.3401 26.2151 29.1075C29.0135 29.875 32.667 30.1824 32.9804 27.5347L10.0402 13.1829Z"
        fill="url(#paint1_linear_1647_30924)"
      />
      <path
        d="M18.494 13.1616C18.494 13.1616 18.8857 11.857 19.3339 11.4109C19.8856 10.8618 21.167 9.76306 21.5361 9.99012C21.8886 10.2072 23.6858 13.6762 23.4116 15.174L18.494 13.1616Z"
        fill="url(#paint2_linear_1647_30924)"
      />
      <path
        d="M15.224 7.27337C14.7806 6.94075 14.0297 6.90755 13.697 7.19636C13.3644 7.48516 14.2893 8.22476 11.5327 10.1448C10.5999 10.7948 10.0143 12.1379 9.71422 13.1258C9.71422 13.1258 9.26873 13.5182 14.5495 17.3821C18.2223 20.0697 20.4869 23.3142 25.0102 26.0509C29.8547 28.9827 33.1119 29.1261 32.9971 27.1145C32.9015 25.4401 26.3997 22.4936 24.8654 18.2877C23.5588 14.7066 24.0435 14.0964 22.2762 13.0069C20.71 12.0423 16.4025 13.1218 15.4265 10.6992C15.2254 10.2006 15.9105 7.78857 15.224 7.27337Z"
        fill="url(#paint3_linear_1647_30924)"
      />
      <path
        d="M17.0793 18.0686L16.3337 17.2978L20.3677 16.3318L21.1132 17.1026L17.0793 18.0686ZM22.7345 18.6999L21.9889 17.9291L17.955 18.8951L18.7006 19.6659L22.7345 18.6999Z"
        fill="url(#paint4_linear_1647_30924)"
      />
      <path
        d="M23.054 15.6984C23.3008 15.6984 23.5008 15.4984 23.5008 15.2516C23.5008 15.0049 23.3008 14.8048 23.054 14.8048C22.8072 14.8048 22.6072 15.0049 22.6072 15.2516C22.6072 15.4984 22.8072 15.6984 23.054 15.6984Z"
        fill="#262626"
      />
      <path
        d="M22.8817 15.4388C22.8425 15.3957 22.8173 15.3399 22.8127 15.2768C22.8027 15.1248 22.9176 14.99 23.0703 14.9747L23.9739 14.8791C24.1266 14.8639 24.2587 14.9754 24.2693 15.1274C24.2793 15.2795 24.1644 15.4143 24.0117 15.4295L23.1081 15.5251C23.0185 15.5331 22.9368 15.4992 22.8817 15.4388Z"
        fill="url(#paint5_linear_1647_30924)"
      />
      <path
        d="M23.4845 16.9678C23.7313 16.9678 23.9313 16.7678 23.9313 16.521C23.9313 16.2743 23.7313 16.0742 23.4845 16.0742C23.2377 16.0742 23.0377 16.2743 23.0377 16.521C23.0377 16.7678 23.2377 16.9678 23.4845 16.9678Z"
        fill="#262626"
      />
      <path
        d="M23.3096 16.7082C23.2705 16.6651 23.2452 16.6093 23.2406 16.5462C23.2306 16.3942 23.3455 16.2594 23.4982 16.2442L24.4018 16.1486C24.5545 16.1333 24.6866 16.2448 24.6972 16.3969C24.7072 16.5489 24.5923 16.6837 24.4396 16.6989L23.536 16.7945C23.4464 16.8025 23.3647 16.768 23.3096 16.7082Z"
        fill="url(#paint6_linear_1647_30924)"
      />
      <path
        d="M23.9747 18.2399C24.2214 18.2399 24.4215 18.0399 24.4215 17.7931C24.4215 17.5463 24.2214 17.3463 23.9747 17.3463C23.7279 17.3463 23.5279 17.5463 23.5279 17.7931C23.5279 18.0399 23.7279 18.2399 23.9747 18.2399Z"
        fill="#262626"
      />
      <path
        d="M23.7998 17.9803C23.7606 17.9371 23.7354 17.8814 23.7307 17.8183C23.7208 17.6663 23.8356 17.5315 23.9883 17.5162L24.8919 17.4206C25.0446 17.4053 25.1767 17.5169 25.1874 17.6689C25.1973 17.821 25.0825 17.9557 24.9298 17.971L24.0262 18.0666C23.9366 18.0746 23.8542 18.0407 23.7998 17.9803Z"
        fill="url(#paint7_linear_1647_30924)"
      />
      <path
        d="M24.4026 19.5093C24.6494 19.5093 24.8494 19.3092 24.8494 19.0625C24.8494 18.8157 24.6494 18.6157 24.4026 18.6157C24.1558 18.6157 23.9558 18.8157 23.9558 19.0625C23.9558 19.3092 24.1558 19.5093 24.4026 19.5093Z"
        fill="#262626"
      />
      <path
        d="M24.2303 19.2497C24.1911 19.2065 24.1659 19.1508 24.1612 19.0877C24.1513 18.9356 24.2661 18.8009 24.4188 18.7856L25.3224 18.69C25.4751 18.6747 25.6073 18.7863 25.6179 18.9383C25.6278 19.0903 25.513 19.2251 25.3603 19.2404L24.4567 19.336C24.3671 19.344 24.2854 19.3094 24.2303 19.2497Z"
        fill="url(#paint8_linear_1647_30924)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1647_30924"
          x1="35.2194"
          y1="7.85001"
          x2="5.82385"
          y2="34.7492"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.396" stopColor="#383838" />
          <stop offset="0.418" stopColor="#313131" />
          <stop offset="0.518" stopColor="#161616" />
          <stop offset="0.606" stopColor="#060606" />
          <stop offset="0.674" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1647_30924"
          x1="25.0764"
          y1="17.026"
          x2="-1.92425"
          y2="41.7334"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.133" stopColor="#DEDEDE" />
          <stop offset="0.207" stopColor="#C9C9C8" />
          <stop offset="0.36" stopColor="#94928F" />
          <stop offset="0.447" stopColor="#73706C" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1647_30924"
          x1="28.0701"
          y1="9.38848"
          x2="16.8262"
          y2="15.611"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.477" stopColor="#00147B" />
          <stop offset="0.517" stopColor="#051465" />
          <stop offset="0.706" stopColor="#000C4D" />
          <stop offset="0.873" stopColor="#041B93" />
          <stop offset="1" stopColor="#041EA9" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1647_30924"
          x1="33.9075"
          y1="7.96282"
          x2="9.515"
          y2="29.9539"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.260417" stopColor="#16057C" />
          <stop offset="0.396" stopColor="#2A1995" />
          <stop offset="0.518" stopColor="#402AC9" />
          <stop offset="0.606" stopColor="#170B5F" />
          <stop offset="0.674" stopColor="#10035C" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1647_30924"
          x1="27.8377"
          y1="16.0577"
          x2="18.6874"
          y2="24.3063"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.185" stopColor="#DEDEDE" />
          <stop offset="0.284" stopColor="#D0CFCF" />
          <stop offset="0.773" stopColor="#8D8B88" />
          <stop offset="1" stopColor="#73706C" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1647_30924"
          x1="23.5438"
          y1="13.4235"
          x2="23.6002"
          y2="15.3762"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.748" stopColor="#D4D4D4" />
          <stop offset="0.824" stopColor="#B1B1B1" />
          <stop offset="0.942" stopColor="#818181" />
          <stop offset="1" stopColor="#6E6E6E" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1647_30924"
          x1="23.9722"
          y1="14.6926"
          x2="24.0282"
          y2="16.6457"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.748" stopColor="#D4D4D4" />
          <stop offset="0.824" stopColor="#B1B1B1" />
          <stop offset="0.942" stopColor="#818181" />
          <stop offset="1" stopColor="#6E6E6E" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_1647_30924"
          x1="24.4615"
          y1="15.9647"
          x2="24.5183"
          y2="17.9177"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.748" stopColor="#D4D4D4" />
          <stop offset="0.824" stopColor="#B1B1B1" />
          <stop offset="0.942" stopColor="#818181" />
          <stop offset="1" stopColor="#6E6E6E" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_1647_30924"
          x1="24.8924"
          y1="17.2337"
          x2="24.9488"
          y2="19.1872"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.748" stopColor="#D4D4D4" />
          <stop offset="0.824" stopColor="#B1B1B1" />
          <stop offset="0.942" stopColor="#818181" />
          <stop offset="1" stopColor="#6E6E6E" />
        </linearGradient>
      </defs>
    </svg>
  );
};
