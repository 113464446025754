import React from "react";

export const Drug = () => {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
    >
      <g>
        <g>
          <path
            fill="currentColor"
            d="M472.196,39.468c-52.654-52.625-138.301-52.625-190.955,0L169.915,150.794l126.394,126.394
			c26.014-21.663,58.995-35.227,95.412-35.227c20.888,0,40.765,4.339,58.851,12.087l21.624-23.624
			C524.835,177.784,524.835,92.123,472.196,39.468z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            fill="currentColor"
            d="M275.27,298.577L148.7,172.008L39.742,281.073c-52.639,52.639-52.639,138.301,0,190.955
			c52.653,52.622,139.3,53.626,191.955,0.999l22.088-22.194c-7.748-18.086-12.087-37.963-12.087-58.851
			C241.699,356.5,254.599,324.291,275.27,298.577z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            fill="currentColor"
            d="M271.703,391.983c0,61.144,45.893,111.045,105.015,118.504V273.478C317.596,280.937,271.703,330.836,271.703,391.983z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            fill="currentColor"
            d="M406.722,273.478v237.009c59.122-7.459,105.015-57.36,105.015-118.505S465.845,280.937,406.722,273.478z"
          />
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
};
