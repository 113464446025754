import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import { Paths } from "@types";
import {
  Auth,
  AppleSuccess,
  AppleError,
  AppleSuccessRegister,
  RegisterStepper,
  ForgotPassword,
  Profile,
  AppleNotExist,
  AppleSuccessBind,
  AppleSuccessBindDelete,
  ChangeEmailStepper,
  Main,
  Logout,
  Demo,
  Dashboard,
  DashboardPatient,
} from "./pages";
import { PrivateRoute } from "@components/PrivateRoute/PrivateRoute";
import "./App.css";
import { useDispatch } from "react-redux";
import { setAuthToken } from "@redux/modules/auth/slice";

const App = () => {
  const dispatch = useDispatch();
  const [isAuth, setIsAuth] = useState(false);

  useEffect(() => {
    const localKey = localStorage.getItem("biodata_key");

    if (localKey) {
      dispatch(setAuthToken({ localKey }));
      setIsAuth(true);
    }
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path={Paths.Root} element={<Auth />} />
        <Route path={Paths.Logout} element={<Logout />} />
        <Route path={Paths.AppleSuccess} element={<AppleSuccess />} />
        <Route path={Paths.AppleError} element={<AppleError />} />
        <Route path={Paths.AppleSuccessRegister} element={<AppleSuccessRegister />} />
        <Route path={Paths.RegisterStepper} element={<RegisterStepper />} />
        <Route path={Paths.ForgotPassword} element={<ForgotPassword />} />
        <Route path={Paths.AppleUserNotExist} element={<AppleNotExist />} />
        <Route path={Paths.AppleSuccessBind} element={<AppleSuccessBind />} />
        <Route path={Paths.AppleSuccessBindDelete} element={<AppleSuccessBindDelete />} />
        <Route path={Paths.Demo} element={<Demo />} />

        <Route
          path={Paths.Main}
          element={
            <PrivateRoute>
              <Main />
            </PrivateRoute>
          }
        />

        <Route
          path={Paths.Profile}
          element={
            <PrivateRoute>
              <Profile />
            </PrivateRoute>
          }
        />

        <Route
          path={Paths.ChangeEmail}
          element={
            <PrivateRoute>
              <ChangeEmailStepper />
            </PrivateRoute>
          }
        />

        <Route
          path={Paths.Dashboard}
          element={
            // <PrivateRoute>
            //   <Dashboard />
            // </PrivateRoute>
            <Dashboard />
          }
        />
        <Route
          path={Paths.DashboardPatient}
          element={
            // <PrivateRoute>
            //   <Dashboard />
            // </PrivateRoute>
            <DashboardPatient />
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
