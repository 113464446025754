import React, { useState } from "react";
import { ConfirmEmail } from "@components/RegisterSteps/ConfirmEmail";
import { EnterName } from "@components/RegisterSteps/EnterName";
import { EnterGoals } from "@components/RegisterSteps/EnterGoals";
import { SuccessRegister } from "@components/RegisterSteps/SuccessRegister";
import { useLocation } from "react-router-dom";
import { StepperType } from "@types";

export const RegisterStepper = () => {
  const { state } = useLocation() as any;
  const type = state.type;

  const [stepNumber, setStepNumber] = useState(type === StepperType.EmailRegister ? 0 : 2);

  switch (stepNumber) {
    case 0:
      return <ConfirmEmail setStepNumber={setStepNumber} />;
    case 1:
      return <EnterName setStepNumber={setStepNumber} />;
    case 2:
      return <EnterGoals setStepNumber={setStepNumber} />;
    case 3:
      return <SuccessRegister type={type} setStepNumber={setStepNumber} />;
  }
  return <ConfirmEmail setStepNumber={setStepNumber} />;
};
