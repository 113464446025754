import React from "react";
import { ArrowTop } from "@assets/icons";
import { LineChart, Line, Tooltip, Legend, ResponsiveContainer } from "recharts";
import classes from "./style.module.scss";

const data = [
  {
    name: "январь",
    bio: 22,
    fact: 26,
  },
  {
    name: "февраль",
    bio: 25,
    fact: 28,
  },
  {
    name: "март",
    bio: 32,
    fact: 36,
  },
  {
    name: "апрель",
    bio: 34,
    fact: 39,
  },
  {
    name: "май",
    bio: 37,
    fact: 44,
  },
  {
    name: "июнь",
    bio: 39,
    fact: 50,
  },
  {
    name: "июль",
    bio: 40,
    fact: 55,
  },
];

const Chart = () => {
  return (
    <ResponsiveContainer width="100%" >
      <LineChart
        width={400}
        height={300}
        data={data}
        margin={{
          top: 0,
          right: 10,
          left: 10,
          bottom: 0,
        }}
      >
        <Tooltip
          labelFormatter={(t) => data[t].name}
          formatter={(value, name, props) => [value, name === "bio" ? "биологический " : " фактический"]}
        />
        <Legend
          align="right"
          iconType="circle"
          payload={[
            { value: "Фактический возраст", type: "circle", color: "#1E4A9C", id: "fact" },
            { value: "Биологический возраст", type: "circle", color: "#34B53A", id: "bio" },
          ]}
        />
        <Line type="monotone" dataKey="bio" stroke="#34B53A" />
        <Line type="monotone" dataKey="fact" stroke="#1E4A9C" />
      </LineChart>
    </ResponsiveContainer>
  );
};

export const AgeChart = () => {
  return (
    <div className={classes.age}>
      <div className={classes.ageHeader}>
        <div>
          <h3>Биологический возраст сотрудников</h3>
          <span>среднее значение</span>
        </div>
        <div className={classes.ageCount}>
        <ArrowTop /> 35%
      </div>
      </div>
      <Chart />
    </div>
  );
};
