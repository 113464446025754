import React from "react";
import classes from "./style.module.scss";
import { TabsProps, Tabs } from "./types";

export const AuthTabs: React.FC<TabsProps> = ({ handleClick, tab }) => {
  return (
    <div className={classes.authTabs}>
      <a
        href="#"
        onClick={() => handleClick(Tabs.Login)}
        className={tab !== Tabs.Login ? classes.disabled : ""}
      >
        Войти
      </a>
      <a
        href="#"
        onClick={() => handleClick(Tabs.Register)}
        className={tab !== Tabs.Register ? classes.disabled : ""}
      >
        Создать аккаунт
      </a>
    </div>
  );
};
