import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "@redux/store";
import { Settings, SignOut } from "@assets/icons";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "antd";
import cn from "classnames";
import { LoadingStatus, Paths } from "@types";
import { DownOutlined } from "@ant-design/icons";
import { logout } from "@redux/modules/auth";
import classes from "./style.module.scss";
import { mapUserType } from "./types";

export const DropdownMenu = ({ name, role, email }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logout());
    navigate(Paths.Root);
  };

  return (
    <div className={classes.dropdownMenuContent}>
      <div>{role}</div>

      <div className={classes.dropdownUser}>
        <span>{name}</span>
        <div>
          <div className={classes.dropdownSettings} onClick={() => {}}>
            <Settings />
          </div>
          <div className={classes.dropdownSignOut} onClick={handleLogout}>
            <SignOut />
          </div>
        </div>
      </div>
      <div>{email}</div>
    </div>
  );
};

const DropdownIcon = ({ isOpen }) => {
  return <DownOutlined className={cn(isOpen && classes.dropdownIconRotate)} />;
};

const TopMenuDropdown = ({ fullName, type, email }) => {
  const [isVisible, setVisible] = useState(false);
  return (
    <Dropdown
      overlayClassName={classes.dropdownPanel}
      overlay={<DropdownMenu email={email} name={fullName} role={mapUserType(type)} />}
      trigger={["click"]}
      onVisibleChange={() => setVisible(!isVisible)}
      placement="bottomRight"
      align={{ offset: [19, 16] }}
    >
      <div className={classes.dropdownButton}>
        <DropdownIcon isOpen={isVisible} />
      </div>
    </Dropdown>
  );
};

export const TopMenu = () => {
  const getUserStatus = useSelector((state: RootState) => state.profile.getUserProfileStatus);
  const userProfile = useSelector((state: RootState) => state.profile.userProfile);
  return (
    <div className={classes.topMenu}>
      {getUserStatus === LoadingStatus.Success && (
        <TopMenuDropdown
          fullName={`${userProfile.firstName} ${userProfile.lastName}`}
          type={userProfile.type}
          email={userProfile.email}
        />
      )}
    </div>
  );
};
