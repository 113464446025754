import React from "react";

export const DietCircle = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" fill="#EBFBE2" />
      <path
        d="M19.6298 20.6926C21.7658 16.2713 21.0131 11.4713 17.9538 9.99329L17.4531 11.0286C19.9418 12.2306 20.4538 16.342 18.5938 20.1926L19.6298 20.6926Z"
        fill="url(#paint0_radial_1647_30416)"
      />
      <path
        d="M20.6787 13.4533C20.2033 13.686 19.652 13.686 19.1767 13.4533C14.8373 11.332 9 13.3073 9 19.5373C9 24.4287 12.6673 31.9727 17.5233 32.3527C18.04 32.3933 18.5927 32.1953 19.148 31.9573C19.68 31.7293 20.2867 31.7327 20.816 31.9667C21.356 32.2047 21.8767 32.396 22.4067 32.3467C27.2253 31.896 30.8553 24.404 30.8553 19.538C30.8547 13.3073 25.018 11.332 20.6787 13.4533Z"
        fill="url(#paint1_radial_1647_30416)"
      />
      <path
        d="M19.2941 11.9393C19.2941 11.9393 20.5741 13.0127 22.8094 11.8547C26.1848 10.1067 25.1254 6 25.1254 6C25.1254 6 25.1668 6.10667 24.1048 6.76667C22.6148 7.69267 21.0627 7.55267 19.9094 8.778C18.5674 10.206 19.2941 11.9393 19.2941 11.9393Z"
        fill="url(#paint2_radial_1647_30416)"
      />
      <defs>
        <radialGradient
          id="paint0_radial_1647_30416"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(15.4707 14.4234) rotate(-41.4415) scale(6.27143 3.65868)"
        >
          <stop stopColor="#CF7C2B" />
          <stop offset="0.474" stopColor="#CD7B2A" />
          <stop offset="0.645" stopColor="#C67628" />
          <stop offset="0.766" stopColor="#BB6E24" />
          <stop offset="0.865" stopColor="#AA631D" />
          <stop offset="0.948" stopColor="#945516" />
          <stop offset="1" stopColor="#82490F" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_1647_30416"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(14.6413 17.9933) scale(10.8967)"
        >
          <stop stopColor="#F8AC91" />
          <stop offset="0.043" stopColor="#F49983" />
          <stop offset="0.297" stopColor="#DF2D32" />
          <stop offset="0.411" stopColor="#D60212" />
          <stop offset="0.672" stopColor="#B1020B" />
          <stop offset="1" stopColor="#860102" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_1647_30416"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(19.2834 7.12947) rotate(-40.1893) scale(7.26295 4.28573)"
        >
          <stop stopColor="#9BCC00" />
          <stop offset="0.46" stopColor="#99CA00" />
          <stop offset="0.626" stopColor="#94C301" />
          <stop offset="0.744" stopColor="#8AB803" />
          <stop offset="0.84" stopColor="#7BA706" />
          <stop offset="0.921" stopColor="#69910A" />
          <stop offset="0.992" stopColor="#52760E" />
          <stop offset="1" stopColor="#4F730F" />
        </radialGradient>
      </defs>
    </svg>
  );
};
