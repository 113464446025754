import React from "react";
import { Radio, RadioChangeEvent } from "antd";

interface Props {
  checked?: boolean;
  disabled?: boolean;
  handleChange: (e: RadioChangeEvent, keyId?: any) => void;
  children: JSX.Element;
  className?: string;
  keyId?: string;
}

export const RadioButton: React.FC<Props> = ({ checked, disabled, handleChange, children, className, keyId }) => {
  return (
    <Radio
      onChange={keyId ? (e) => handleChange(e, keyId) : handleChange}
      checked={checked}
      disabled={disabled}
      className={className}
    >
      {children}
    </Radio>
  );
};
