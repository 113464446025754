import React from "react";

export const Search = () => {
  return (
    <svg height="32px" viewBox="0 0 511 511.99975" width="32px" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="m15.5 302h62c8.289062 0 15-6.710938 15-15v-150c0-8.289062-6.710938-15-15-15h-62c-8.292969 0-15 6.710938-15 15v150c0 8.289062 6.707031 15 15 15zm0 0"
      />
      <path
        fill="currentColor"
        d="m503.707031 460.8125-85.390625-85.394531c-5.855468-5.855469-15.339844-5.855469-21.199218-.011719l-21.386719-21.386719c10.46875-14.710937 16.769531-32.589843 16.769531-52.019531 0-49.707031-40.292969-90-90-90s-90 40.292969-90 90 40.292969 90 90 90c19.429688 0 37.308594-6.300781 52.019531-16.769531l21.386719 21.382812-.011719.011719c-5.859375 5.859375-5.859375 15.355469 0 21.214844l85.390625 85.394531c11.703125 11.699219 30.742188 11.675781 42.421875 0 11.691407-11.691406 11.691407-30.734375 0-42.421875zm0 0"
      />
      <path
        fill="currentColor"
        d="m392.5 15c0-8.289062-6.710938-15-15-15h-60c-8.292969 0-15 6.710938-15 15v167c36.011719 0 67.988281 16.273438 90 41.472656zm0 0"
      />
      <path
        fill="currentColor"
        d="m182.5 302c0-44.214844 24.308594-82.488281 60-103.304688v-121.695312c0-8.289062-6.710938-15-15-15h-60c-8.292969 0-15 6.710938-15 15v210c0 8.289062 6.707031 15 15 15zm0 0"
      />
    </svg>
  );
};
