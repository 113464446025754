import React, { useState, useEffect, SetStateAction, Dispatch } from "react";
import { RootState, AppDispatch } from "@redux/store";
import { AlertTwoTone } from "@ant-design/icons";
import { Textfield, Button, StrokeButton, Notification } from "@common";
import classes from "./style.module.scss";
import { onlyNumbersRegex } from "@utils/const";
import { useSelector, useDispatch } from "react-redux";
import { ErrorMessages } from "@components/Auth/types";
import { useNavigate } from "react-router-dom";
import { sendEmailConfirm, confirmEmail, logout, clearConfirmStatus, checkGoalsAlreadyExist } from "@redux/modules/auth";
import { LoadingStatus, Paths, ConfirmErrorMessage } from "@types";

interface Props {
  setStepNumber: Dispatch<SetStateAction<number>>;
}

export const ConfirmEmail: React.FC<Props> = ({ setStepNumber }) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [code, setCode] = useState("");
  const [seconds, setSeconds] = useState(59);
  const [startTimer, setStart] = useState(false);
  const [isCodeSend, setIsSend] = useState(false);
  const [notification, setNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const email = useSelector((state: RootState) => state.auth.user?.email);
  const sendEmailConfirmStatus = useSelector((state: RootState) => state.auth.sendEmailConfirmStatus);
  const authKey = useSelector((state: RootState) => state.auth.user?.auth_key) as string;
  const confirmStatus = useSelector((state: RootState) => state.auth.confirmStatus);
  const confirmEmailMessage = useSelector((state: RootState) => state.auth.confirmEmailMessage);

  const checkGoalsAlreadyExistStatus = useSelector((state:RootState) => state.auth.checkGoalsAlreadyExistStatus);
  const checkGoalsAlreadyExistValues = useSelector((state:RootState) => state.auth.checkGoalsAlreadyExist);

  useEffect(() => {
    if(checkGoalsAlreadyExistStatus === LoadingStatus.Success) {
      if(checkGoalsAlreadyExistValues.length > 0) {
        setStepNumber(3);
      }
      else {
        setStepNumber(1)
      }
    }
  },[checkGoalsAlreadyExistStatus])

  useEffect(() => {
    if (confirmStatus === LoadingStatus.Success) {
      dispatch(checkGoalsAlreadyExist(authKey))
    }

    if (confirmStatus === LoadingStatus.Error) {
      if (confirmEmailMessage === ConfirmErrorMessage.AttemptsExhausted) {
        navigate(Paths.Root);
        dispatch(logout());
      } else {
        setNotification(true);
        setNotificationMessage(confirmEmailMessage);
      }
    }
    return () => {
      dispatch(clearConfirmStatus());
    };
  }, [confirmStatus, confirmEmailMessage]);

  const handleChangeCode = (e) => {
    const value = e.target.value;
    if (value.match(onlyNumbersRegex) || value === "") {
      setCode(e.target.value);
    }
  };

  const handleSendCode = () => {
    setStart(true);
    setIsSend(true);
    if (authKey) {
      dispatch(confirmEmail({ code, token: authKey }));
    }
  };

  const handleResendCode = () => {
    setStart(true);
    setSeconds(59);
    if (email) {
      dispatch(sendEmailConfirm({ email, token: authKey }));
    }
  };

  useEffect(() => {
    if (email?.length) {
      dispatch(sendEmailConfirm({ email, token: authKey }));
    }
  }, []);

  useEffect(() => {
    if (startTimer && sendEmailConfirmStatus === LoadingStatus.Success) {
      const timer = setTimeout(() => setSeconds(seconds - 1), 1000);
      if (seconds === 0) {
        setStart(false);
      }
      return () => {
        clearTimeout(timer);
      };
    }
  }, [startTimer, seconds, sendEmailConfirmStatus]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <h2 className={classes.title}>Подтвердите адрес</h2>
        <p className={classes.description}>
          На адрес {email} отправлен четырёхзначный код активации – пожалуйста, введите его здесь чтобы подтвердить
          адрес электронной почты
        </p>
        <div className={classes.input}>
          <Textfield value={code} handleChange={handleChangeCode} className={classes.code} maxLength={4} />
        </div>
        <div className={classes.button}>
          <Button
            handleClick={handleSendCode}
            text={"Подтвердить"}
            disabled={!code.match(onlyNumbersRegex) || code.length !== 4}
          />
        </div>
        <div className={classes.button}>
          {isCodeSend && (
            <StrokeButton
              text={startTimer ? `Отправить повторно через ${seconds}` : "Отправить повторно"}
              handleClick={handleResendCode}
              disabled={startTimer}
            />
          )}
        </div>
      </div>
      <Notification
        title={ErrorMessages.Title}
        message={notificationMessage}
        isOpen={notification}
        icon={<AlertTwoTone twoToneColor="#eb2f96" />}
        onClose={() => setNotification(false)}
        duration={1}
      />
    </div>
  );
};
