import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "@redux/store";
import cn from "classnames";

import classes from "./style.module.scss";
import { MainMenu } from "@components/MainMenu/MainMenu";
import { TopMenu } from "@components/TopMenu/TopMenu";

import { getUserProfile } from "@redux/modules/profile";
import { useDispatch } from "react-redux";
import { useAuth } from "@utils/hooks";
import { Goals } from "./Goals";
import { BiomarkersChart } from "./BiomarkersChart";
import { Schedule } from "./Schedule";
import { Recomendations } from "./Recommendations";

export const DashboardLayout = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { isAuth, key } = useAuth();

  useEffect(() => {
    if (key) {
      dispatch(getUserProfile(key));
    }
  }, [key]);

  return (
    <div className={classes.wrapper}>
      <MainMenu />

      <div className={classes.main}>
        <TopMenu />
        <div className={classes.content}>
          <div className={classes.container}>
            <Goals />
            <BiomarkersChart />
            {/*<AgeChart /> */}
          </div>

          <div className={classes.container}>
            <Recomendations />
            <Schedule />
          </div>
        </div>
      </div>
    </div>
  );
};
