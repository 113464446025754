import { Calendar, Drug, Files, Form, Genetic, Graph, Home, Search, Zipper, Profile } from "@assets/icons";

export const demoMenu = [
  {
    id: 7,
    title: "Файлы",
    icon: Files,
    url: "/user-files/index",
  },
  {
    id: 2,
    title: "Задачи",
    icon: Zipper,
    url: "/risk-panel/index",
  },
  {
    id: 1,
    title: "Анализы",
    icon: Search,
    url: "/biomarkers/full-view",
  },
  {
    id: 3,
    title: "Профиль",
    icon: Profile,
    url: "/profile",
  },
];

export const menu = [
  {
    id: 0,
    title: "Главная",
    icon: Home,
    url: "/profile/index",
  },
  {
    id: 1,
    title: "Анализы",
    icon: Search,
    url: "/biomarkers/full-view",
  },
  {
    id: 2,
    title: "Задачи",
    icon: Zipper,
    url: "/risk-panel/index",
  },
  {
    id: 3,
    title: "Рекомендации",
    icon: Drug,
    url: "/recommendations/list",
  },
  {
    id: 4,
    title: "Дневник",
    icon: Calendar,
    url: "/diary/index",
  },
  {
    id: 5,
    title: "Генетика",
    icon: Genetic,
    url: "/genetics/risks-all",
  },
  {
    id: 6,
    title: "Анкета",
    icon: Form,
    url: "/poll/index",
  },
  {
    id: 7,
    title: "Файлы",
    icon: Files,
    url: "/user-files/index",
  },
  {
    id: 8,
    title: "Инфографика",
    icon: Graph,
    url: "/chart/index",
  },
  // {
  //   id: 9,
  //   title: "Подписка",
  //   icon: Subscription,
  //   url: "/personal/faq/",
  // },
];
