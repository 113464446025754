import React from "react";
import { Checkbox as ACheckbox } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

interface Props {
  checked: boolean;
  handleChange: (e: CheckboxChangeEvent, keyId?: string) => void;
  disabled?: boolean;
  keyId?: string;
}

export const Checkbox: React.FC<Props> = ({
  checked,
  handleChange,
  disabled,
  keyId,
}) => {
  return (
    <ACheckbox
      onChange={keyId ? (e) => handleChange(e, keyId) : (e) => handleChange(e)}
      checked={checked}
      disabled={disabled}
    />
  );
};
