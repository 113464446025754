import React, { SetStateAction, Dispatch, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Paths } from "@types";
import classes from "./style.module.scss";

interface Props {
  setStepNumber: Dispatch<SetStateAction<number>>;
}

export const SuccessChange: React.FC<Props> = ({ setStepNumber }) => {
  const [seconds, setSeconds] = useState(5);
  const [startTimer, setStart] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setStart(true);
  }, []);

  useEffect(() => {
    if (startTimer) {
      const timer = setTimeout(() => setSeconds(seconds - 1), 1000);
      if (seconds === 0) {
        setStart(false);
        navigate(Paths.Root);
      }
      return () => {
        clearTimeout(timer);
      };
    }
  }, [startTimer, seconds]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <h2 className={classes.title}>Пароль успешно изменен</h2>
        <p className={classes.description}>Вы будете перенаправлены на страницу авторизации через {seconds}</p>
      </div>
    </div>
  );
};
