import { ArrowTop, DietCircle, Activity, Sleep } from "@assets/icons";
import React from "react";
import classes from "./style.module.scss";

const Progress = ({ numm }) => {
  return (
    <div className={classes.progress}>
      <div className={classes.active} style={{ width: `${numm}%` }} />
    </div>
  );
};

const recomendations = [
  {
    id: 0,
    name: "Питание",
    description: "Выполнено",
    count: 25,
    image: <DietCircle />,
  },
  {
    id: 1,
    name: "Активность",
    description: "Выполнено",
    count: 75,
    image: <Activity />,
  },
  {
    id: 2,
    name: "Сон",
    description: "Выполнено",
    count: 100,
    image: <Sleep />,
  },
];

const Item = ({ name, image, description, count }) => {
  return (
    <div className={classes.recomendationsItem}>
      <div className={classes.recomendationImage}>{image}</div>
      <div className={classes.recomendationContent}>
        <h3>{name}</h3>
        <p>{description}</p>
        <Progress numm={count} />
      </div>
      <div className={classes.recomendationCount}>
        <ArrowTop /> {count}%
      </div>
    </div>
  );
};

export const Recomendations = () => {
  return (
    <div className={classes.recomendations}>
      {recomendations.map((item) => {
        return (
          <Item key={item.name} name={item.name} description={item.description} count={item.count} image={item.image} />
        );
      })}
    </div>
  );
};
