import React, { useEffect, useState, Dispatch, SetStateAction } from "react";
import { useDispatch } from "react-redux";
import { login, loginSocial } from "@redux/modules/auth";

import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "@redux/store";
import { LoadingStatus, SocialLoginTypes, StepperType } from "@types";

import classes from "./style.module.scss";

interface Props {
  setStepNumber: Dispatch<SetStateAction<number>>;
  type: StepperType;
}

export const SuccessRegister: React.FC<Props> = ({ setStepNumber, type }) => {
  const [seconds, setSeconds] = useState(5);

  const dispatch = useDispatch<AppDispatch>();
  const username = useSelector((state: RootState) => state.auth.inputUsername);
  const password = useSelector((state: RootState) => state.auth.inputPassword);
  const loginStatus = useSelector((state: RootState) => state.auth.loginStatus);
  const loginSocialStatus = useSelector((state: RootState) => state.auth.loginSocialStatus);
  const facebookToken = useSelector((state: RootState) => state.auth.facebookRegisterToken);
  const appleToken = useSelector((state: RootState) => state.auth.appleRegisterToken);

  useEffect(() => {
    if (loginStatus === LoadingStatus.Success || loginSocialStatus === LoadingStatus.Success) {
      window.location.href = `${process.env.REACT_APP_OLD}/profile/index`;
    }
  }, [loginStatus, loginSocialStatus]);

  useEffect(() => {
    const timer = setTimeout(() => setSeconds(seconds - 1), 1000);
    if (seconds === 1) {
      if (type === StepperType.EmailRegister) {
        dispatch(
          login({
            username,
            password,
            isOriginalLogin: true,
          })
        );
      }
      if (type === StepperType.AppleRegister) {
        window.location.href = `${process.env.REACT_APP_OLD}/profile/index`;
        // dispatch(
        //   loginSocial({
        //     socialName: SocialLoginTypes.Apple,
        //     socialToken: appleToken,
        //   })
        // );
      }

      if (type === StepperType.FacebookRegister) {
        window.location.href = `${process.env.REACT_APP_OLD}/profile/index`;
        // loginSocial({
        //   socialName: SocialLoginTypes.Facebook,
        //   socialToken: facebookToken as string,
        // });
      }
      clearTimeout(timer);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [seconds]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <h2 className={classes.title}>Регистрация прошла успешно!</h2>
        <p>Вы будете перенаправлены в Ваш личный кабинет через {seconds}</p>
      </div>
    </div>
  );
};
