import { Activity, Sleep, AppleIcon } from "@assets/icons";
import React from "react";
import classes from "./style.module.scss";

const data = [
  {
    id: 0,
    name: "Название рекомендации",
    time: "В любое время",
    icon: Sleep,
  },
  {
    id: 1,
    name: "Название рекомендации",
    time: "На этой неделе",
    icon: Activity,
  },
  {
    id: 2,
    name: "Название рекомендации",
    time: "В этот месяц",
    icon: Sleep,
  },
];

export const Recomendations = () => {
  return (
    <div className={classes.recommendations}>
      <h3>Рекомендации</h3>
      <div className={classes.timeline}>
        {data.map((item) => {
          return (
            <React.Fragment key={item.id + item.name}>
              <div className={classes.timelineItem}>
                <span>
               
                  <span className={classes.circle} />
                  {item.time}
                </span>
                <div className={classes.item}>
                  <item.icon />
                  <span>{item.name}</span>
                </div>
              </div>
            </React.Fragment>
          );
        })}

        {/* <span className={classes.circle} />
        <div className={classes.timelineItem}>
          <span>В любое время</span>
          <div className={classes.item}>
            <Sleep />
            <span>Название рекомендации</span>
          </div>
        </div> */}
      </div>
    </div>
  );
};
