import React, { useEffect, useState } from "react";
import { Routes } from "../../redux/modules/auth/types";
import { Logo, Button } from "@common";
import type { UploadProps } from "antd";
import { message, Upload } from "antd";
import { createDemo, uploadDemo } from "@redux/modules/profile";

import classes from "./style.module.scss";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "@redux/store";
import { useSelector } from "react-redux";
import { LoadingStatus } from "@types";

const baseUrl = process.env.REACT_APP_BASE_URL;

export const DemoContent = () => {
  const dispatch = useDispatch<AppDispatch>();
  const token = localStorage.getItem("biodata_key");
  const [isDisable, setDisable] = useState(true);

  const createDemoStatus = useSelector((state: RootState) => state.profile.createDemoAccount);

  const props: UploadProps = {
    name: "file",
    accept: "image/jpeg,image/gif,image/png,application/pdf,image/x-eps",
    action: `${baseUrl}${Routes.Demo}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      typeId: 100,
      comment: "",
      autoprovide: "1",
      phasesCycleId: "1",
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} загружен успешно!`);
        window.location.href = `${process.env.REACT_APP_OLD}/profile/index`;
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} загружен с ошибкой!`);
      }
    },
  };

  useEffect(() => {
    dispatch(createDemo());
  }, []);

  useEffect(() => {
    if (createDemoStatus === LoadingStatus.Success) {
      setDisable(false);
    }
    return () => {
      setDisable(true);
    };
  }, [createDemoStatus]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <Logo />
        <h3 className={classes.title}>Демонстрационная версия</h3>
        <Upload {...props}>
          <Button text={"Загрузить файл"} handleClick={() => {}} />
        </Upload>
      </div>
    </div>
  );
};
