import React, { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { AlertTwoTone } from "@ant-design/icons";

import { AppDispatch } from "@redux/store";
import { Logo, Notification, Loader } from "@common";
import { LoadingStatus, ConfirmErrorMessage, ResetPassErrors, StepperType, Paths } from "@types";
import { Tabs, AuthInput, initialAuth, initialAuthValidate, RegisterErrorMessages, ErrorMessages } from "./types";
import { getCookie, validateEmail, validatePass } from "@utils/functions";
import { login, register, clearRegisterStatus, clearLoginStatus, createSession } from "@redux/modules/auth";

import { RootState } from "@redux/store";
import { LoginTab } from "./LoginTab";
import { RegisterTab } from "./RegisterTab";
import { AuthTabs } from "./AuthTabs";
import { Disclaimer } from "./Disclaimer";

import classes from "./style.module.scss";

export const AuthForm = () => {
  return (
    <div className={classes.authContainer}>
      <Form />
      <Disclaimer />
    </div>
  );
};

export const Form = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [activeTab, setActive] = useState(Tabs.Login);
  const [auth, setAuth] = useState(initialAuth);
  const [authValidate, setAuthValidate] = useState(initialAuthValidate);
  const [authTouched, setTouched] = useState(false);
  const [notification, setNotification] = useState(false);
  const [errorMessage, setErrorMessage] = useState(ErrorMessages.Common as string);
  const [isApproveAgreement, setAgreement] = useState(false);

  const loginStatus = useSelector((state: RootState) => state.auth.loginStatus);
  const loginSocialStatus = useSelector((state: RootState) => state.auth.loginSocialStatus);
  const registerStatus = useSelector((state: RootState) => state.auth.registerStatus);
  const auth_key = useSelector((state: RootState) => state.auth?.user?.auth_key);
  const signOutErrorMessage = useSelector((state: RootState) => state.auth?.signUpErrorMessage);
  const signInErrorMessage = useSelector((state: RootState) => state.auth?.signInErrorMessage);
  const isEmailConfirmed = useSelector((state: RootState) => state.auth.user?.email_confirmed);
  const resetPasswordStatus = useSelector((state: RootState) => state.auth.approveNewPasswordStatus);
  const resetPasswordMessage = useSelector((state: RootState) => state.auth.approvePasswordMessage);
  const confirmStatus = useSelector((state: RootState) => state.auth.confirmStatus);
  const confirmEmailMessage = useSelector((state: RootState) => state.auth.confirmEmailMessage);
  const createSessionStatus = useSelector((state: RootState) => state.auth.createSessionStatus);

  const handleChangeTab = (tab: Tabs) => {
    setTouched(false);
    setActive(tab);
    setAuth(initialAuth);
    setAuthValidate(initialAuthValidate);
  };

  const validateFields = (value: string, keyId: AuthInput) => {
    switch (keyId) {
      case AuthInput.Email:
        return validateEmail(value);
      case AuthInput.Pass:
        return validatePass(value);
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>, keyId: AuthInput) => {
    const value = e.target.value;
    setAuth({
      ...auth,
      [keyId]: value,
    });

    setAuthValidate({
      ...authValidate,
      [keyId]: validateFields(value, keyId),
    });
  };

  const handleAuth = () => {
    setTouched(true);
    if (authValidate[AuthInput.Email] && authValidate[AuthInput.Pass]) {
      dispatch(
        login({
          username: auth[AuthInput.Email],
          password: auth[AuthInput.Pass],
          isOriginalLogin: true,
        })
      );
    }
  };

  useEffect(() => {
    if (createSessionStatus === LoadingStatus.Success) {
      window.location.href = `${process.env.REACT_APP_OLD}/profile/index`;
    }
  }, [createSessionStatus]);

  useEffect(() => {
    const key = localStorage.getItem("biodata_key");
    if (key) { // TODO: очищать key
      dispatch(createSession(key));
    }
  }, []);

  useEffect(() => {
    if (loginStatus === LoadingStatus.Success && !isEmailConfirmed) {
      navigate("/register/quiz", { state: { type: StepperType.EmailRegister } });
    }
    if (loginStatus === LoadingStatus.Success && isEmailConfirmed) {
      window.location.href = `${process.env.REACT_APP_OLD}/profile/index`;
    }

    if (loginStatus === LoadingStatus.Error) {
      setNotification(true);
      if (signInErrorMessage) {
        setErrorMessage(signInErrorMessage);
      }
    }
    return () => {
      dispatch(clearLoginStatus());
    };
  }, [loginStatus]);

  const handleRegister = () => {
    setTouched(true);
    if (!authValidate[AuthInput.Pass]) {
      setNotification(true);
      setErrorMessage(ErrorMessages.TooShortPass);
    }
    if (authValidate[AuthInput.Email] && authValidate[AuthInput.Pass]) {
      dispatch(
        register({
          email: auth[AuthInput.Email],
          password: auth[AuthInput.Pass],
        })
      );
    }
  };

  useEffect(() => {
    if (registerStatus === LoadingStatus.Success && auth_key) {
      navigate("/register/quiz", { state: { type: StepperType.EmailRegister } });
    }

    if (registerStatus === LoadingStatus.Error && signOutErrorMessage === RegisterErrorMessages.AlreadyExist) {
      setNotification(true);
      setErrorMessage(signOutErrorMessage);
      dispatch(
        login({
          username: auth[AuthInput.Email],
          password: auth[AuthInput.Pass],
          isOriginalLogin: false,
        })
      );

      if (registerStatus === LoadingStatus.Error && signOutErrorMessage !== RegisterErrorMessages.AlreadyExist) {
        if (signOutErrorMessage) {
          setNotification(true);
          setErrorMessage(signOutErrorMessage);
        }
      }
    }

    return () => {
      dispatch(clearRegisterStatus());
    };
  }, [registerStatus]);

  useEffect(() => {
    if (resetPasswordStatus === LoadingStatus.Error && resetPasswordMessage === ResetPassErrors.AttemptsExhausted) {
      setNotification(true);
      setErrorMessage(ResetPassErrors.AttemptsExhausted);
    }

    return () => {
      setNotification(false);
      setErrorMessage("");
    };
  }, [resetPasswordStatus, resetPasswordMessage]);

  useEffect(() => {
    if (confirmStatus === LoadingStatus.Error && confirmEmailMessage === ConfirmErrorMessage.AttemptsExhausted) {
      setNotification(true);
      setErrorMessage(ConfirmErrorMessage.AttemptsExhausted);
    }
    return () => {
      setNotification(false);
      setErrorMessage("");
    };
  }, [confirmEmailMessage, confirmStatus]);

  return (
    <>
      {loginSocialStatus === LoadingStatus.Pending || createSessionStatus === LoadingStatus.Pending ? (
        <Loader />
      ) : (
        <div className={classes.authForm}>
          <div className={classes.authLogo}>
            <Logo />
          </div>
          <h2 className={classes.authTitle}>Добро пожаловать!</h2>
          <AuthTabs tab={activeTab} handleClick={handleChangeTab} />
          {activeTab === Tabs.Login && (
            <LoginTab
              handleAuth={handleAuth}
              handleChange={handleChange}
              authTouched={authTouched}
              auth={auth}
              authValidate={authValidate}
            />
          )}

          {activeTab === Tabs.Register && (
            <RegisterTab
              auth={auth}
              handleRegister={handleRegister}
              handleChange={handleChange}
              authTouched={authTouched}
              authValidate={authValidate}
              isApproveAgreement={isApproveAgreement}
              setAgreement={setAgreement}
            />
          )}
          <Notification
            title={ErrorMessages.Title}
            message={errorMessage}
            isOpen={notification}
            icon={<AlertTwoTone twoToneColor="#eb2f96" />}
            onClose={() => setNotification(false)}
            duration={1}
          />
        </div>
      )}
    </>
  );
};
