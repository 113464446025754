import { logout } from "@redux/modules/auth";
import { Paths } from "@types";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

export const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(logout());
    navigate(Paths.Root);
  }, []);

  return <></>;
};
