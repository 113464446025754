import React, { ChangeEvent } from "react";
import { DatePicker } from "antd";
import "moment/locale/ru";
import locale from "antd/es/date-picker/locale/ru_RU";
import classes from "./Datepicker.module.scss";

export enum CalendarTypes {
  Week = "week",
  Month = "month",
  Quarter = "quarter",
  Year = "year",
}

interface Props {
  handleChange: (date: any, dateString: string, type?: any) => void;
  placeholder?: string;
  picker?: CalendarTypes;
  format?: string;
  keyId: string;
  value: any;
  allowClear?: boolean;
}

export const Datepicker: React.FC<Props> = ({
  handleChange,
  placeholder,
  picker,
  format,
  keyId,
  value,
  allowClear,
}) => {
  return (
    <DatePicker
      placeholder={placeholder}
      className={classes.datepicker}
      onChange={(date: any, dateString: string) => handleChange(date, dateString, keyId)}
      picker={picker}
      format={format}
      value={value}
      locale={locale}
      allowClear={allowClear}
    />
  );
};
