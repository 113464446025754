import React from "react";
import { useNavigate } from "react-router-dom";
import { FacebookBlock } from "./FacebookBlock";
import { AppleBlock, Redirects } from "./AppleBlock";
import { Textfield, Button } from "@common";
import { InputTypes, InputStatus } from "@types";
import { AuthInput, FacebookTypes, LoginTabProps } from "./types";
import { Paths } from "@types";
import classes from "./style.module.scss";

export const LoginTab: React.FC<LoginTabProps> = ({ handleChange, auth, authTouched, authValidate, handleAuth }) => {
  const navigate = useNavigate();
  return (
    <>
      <h3 className={classes.authSocTitle}>Войти с помощью:</h3>
      <FacebookBlock type={FacebookTypes.Login} />
      <AppleBlock type={Redirects.Login} />
      <h3 className={classes.authSocTitle}>Войти с помощью эл. почты:</h3>
      <div className={classes.authInput}>
        <Textfield
          handleChange={handleChange}
          placeholder="Эл почта"
          value={auth[AuthInput.Email]}
          keyId={AuthInput.Email}
          type={InputTypes.Text}
          status={authTouched && !authValidate[AuthInput.Email] ? InputStatus.Error : InputStatus.Success}
        />
      </div>
      <div className={classes.authInput}>
        <Textfield
          handleChange={handleChange}
          placeholder="Пароль"
          value={auth[AuthInput.Pass]}
          keyId={AuthInput.Pass}
          type={InputTypes.Pass}
          status={authTouched && !authValidate[AuthInput.Pass] ? InputStatus.Error : InputStatus.Success}
        />
      </div>
      <a
        href=""
        onClick={() => {
          navigate(Paths.ForgotPassword);
        }}
        className={classes.authForgot}
      >
        Забыли пароль?
      </a>
      <Button
        handleClick={handleAuth}
        text="Войти"
        disabled={!auth[AuthInput.Email].length || !auth[AuthInput.Pass].length}
      />
    </>
  );
};
