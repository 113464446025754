import { RootState } from "@redux/store";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

export const AppleError = () => {
  const registerSocialErrorMessage = useSelector((state: RootState) => state.auth.registerSocialErrorMessage);

  // Ошибка при получении данных: Already exist
  return <>{registerSocialErrorMessage}</>;
};
