import { SocialLoginTypes } from "@types";
import { initialBinds, UserTypes } from "./types";
import moment from "moment";

export const calculateBinds = (socialList: Array<string>) => {
  let obj = initialBinds;
  for (let i = 0; i < socialList.length; i++) {
    if (socialList[i] === SocialLoginTypes.Apple) {
      obj = { ...obj, apple: true };
    }
    if (socialList[i] === SocialLoginTypes.Facebook) {
      obj = { ...obj, facebook: true };
    }
  }

  return obj;
};

export const mapSaveProfile = (data: any) => {
  const date_string = `${data.year}.${data.month}.01`;
  return {
    first_name: data.firstName || "",
    last_name: data.lastName || "",
    birthday_date: moment(date_string).add(1, "day").unix() || "",
    email: data.email || "",
    sex: data.gender,
  };
};

export const mapUserType = (type: number) => {
  switch (type) {
    case 0:
      return "";
    case 1:
      return UserTypes.Admin;
    case 2:
      return UserTypes.Consultant;
    case 3:
      return UserTypes.Client;
    case 4:
      return UserTypes.Assistant;
    case 5:
      return UserTypes.MainConsultant;
    case 6:
      return UserTypes.Bioinformatician;
    case 7:
      return UserTypes.Employee;
    default:
      return UserTypes.Employee;
  }
};
