import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { AppDispatch, RootState } from "@redux/store";
import { loginSocial } from "@redux/modules/auth/slice";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LoadingStatus, SocialLoginTypes } from "@types";

export const AppleSuccess = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get("data");

  const loginSocialStatus = useSelector((state: RootState) => state.auth.loginSocialStatus);
  const result = useSelector((state: RootState) => state.auth.user?.result);

  useEffect(() => {
    if (token) {
      dispatch(
        loginSocial({
          socialName: SocialLoginTypes.Apple,
          socialToken: window.atob(token),
        })
      );
    }
  }, []);

  useEffect(() => {
    if (loginSocialStatus === LoadingStatus.Success) {
      // if (result === false) {
      //   navigate("/apple/error");
      // }
      // window.location.href = "https://account.testzone51.ru/profile/index";
      window.location.href = `${process.env.REACT_APP_OLD}/profile/index`;
    }
  }, [loginSocialStatus]);

  return <></>;
};
