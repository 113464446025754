import React, { useState } from "react";
import { Hamburger } from "@assets/icons";
import cn from "classnames";
import { useSelector } from "react-redux";
import { LegacyLogo } from "@common";
import { RootState } from "@redux/store";
import { menu, demoMenu } from "./types";
import classes from "./style.module.scss";

export const HamburgerButton = ({ handleClick }) => {
  return (
    <div onClick={handleClick} className={classes.hamburgerButton}>
      <Hamburger />
    </div>
  );
};

export const MainMenu = () => {
  const [isHide, setHide] = useState(false);
  const userProfile = useSelector((state: RootState) => state.profile.userProfile);

  const handleToggle = () => {
    setHide(!isHide);
  };
  return (
    <div className={cn(classes.menu, isHide && classes.menuHide)}>
      {!isHide && <LegacyLogo />}
      <div className={classes.mainMenu}>
        <HamburgerButton handleClick={handleToggle} />
        {userProfile?.dataset !== "demo" &&
          menu.map((item) => {
            // eslint-disable-next-line no-lone-blocks
            {
              return isHide ? (
                <a className={classes.menuItem} key={item.id} href={`${process.env.REACT_APP_OLD}${item.url}`}>
                  {<item.icon />}
                </a>
              ) : (
                <a className={classes.menuItem} key={item.id} href={`${process.env.REACT_APP_OLD}${item.url}`}>
                  {<item.icon />}
                  <span>{item.title}</span>
                </a>
              );
            }
          })}

        {userProfile?.dataset === "demo" &&
          demoMenu.map((item) => {
            // eslint-disable-next-line no-lone-blocks
            {
              return isHide ? (
                <a className={classes.menuItem} key={item.id} href={`${process.env.REACT_APP_OLD}${item.url}`}>
                  {<item.icon />}
                </a>
              ) : (
                <a className={classes.menuItem} key={item.id} href={`${process.env.REACT_APP_OLD}${item.url}`}>
                  {<item.icon />}
                  <span>{item.title}</span>
                </a>
              );
            }
          })}
      </div>
    </div>
  );
};
