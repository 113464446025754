import React, { SetStateAction, Dispatch, useEffect, useState } from "react";
import { Textfield, Button, Notification } from "@common";
import classes from "./style.module.scss";

import { AlertTwoTone } from "@ant-design/icons";
import { validateEmail } from "@utils/functions";
import { useDispatch } from "react-redux";
import { changeEmail, clearChangeEmalStatus } from "@redux/modules/profile";
import { AppDispatch, RootState } from "@redux/store";
import { useSelector } from "react-redux";
import { LoadingStatus } from "@types";

import { ErrorMessages } from "@components/Auth/types";

interface Props {
  setStepNumber: Dispatch<SetStateAction<number>>;
  setEmail: Dispatch<SetStateAction<string>>;
  email: string;
}

export const ChangeEmail: React.FC<Props> = ({ setStepNumber, setEmail, email }) => {
  const dispatch = useDispatch<AppDispatch>();
  const changeEmailStatus = useSelector((state: RootState) => state.profile.changeEmailStatus);
  const [notification, setNotification] = useState(false);
  const token = localStorage.getItem("biodata_key");

  const handleChangeEmail = () => {
    dispatch(changeEmail({ email, token }));
  };

  useEffect(() => {
    if (changeEmailStatus === LoadingStatus.Error) {
      setNotification(true)
    }
    if (changeEmailStatus === LoadingStatus.Success) {
      setStepNumber(1);
    }
  }, [changeEmailStatus]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <h2 className={classes.title}>Введите новый email</h2>
        <div className={classes.input}>
          <Textfield value={email} handleChange={(e) => setEmail(e.target.value)} />
        </div>
        <div className={classes.button}>
          <Button handleClick={handleChangeEmail} text={"Сменить"} disabled={!validateEmail(email)} />
        </div>
      </div>
      <Notification
        title={ErrorMessages.Title}
        message={ErrorMessages.EmailAlreadyExist}
        isOpen={notification}
        icon={<AlertTwoTone twoToneColor="#eb2f96" />}
        onClose={() => setNotification(false)}
        duration={1}
      />
    </div>
  );
};
