import React from "react";
import { LineChart, Line, Tooltip, Legend, ResponsiveContainer } from "recharts";
import classes from "./style.module.scss";

const data = [
  {
    name: "январь",
    bio1: 5,
    bio2: 10,
    bio3: 56,
    bio4: 35,
  },
  {
    name: "февраль",
    bio1: 6,
    bio2: 15,
    bio3: 43,
    bio4: 35,
  },
  {
    name: "март",
    bio1: 7,
    bio2: 19,
    bio3: 32,
    bio4: 40,
  },
  {
    name: "апрель",
    bio1: 8,
    bio2: 10,
    bio3: 33,
    bio4: 45,
  },
  {
    name: "май",
    bio1: 2,
    bio2: 10,
    bio3: 34,
    bio4: 43,
  },
  {
    name: "июнь",
    bio1: 4,
    bio2: 5,
    bio3: 50,
    bio4: 42,
  },
  {
    name: "июль",
    bio1: 10,
    bio2: 38,
    bio3: 45,
    bio4: 39,
  },
];

const Chart = () => {
  const formatTooltip = (value, name, props) => {
    switch (name) {
      case "bio1":
        return [value, "биомаркер 1"];
      case "bio2":
        return [value, "биомаркер 2"];
      case "bio3":
        return [value, "биомаркер 3"];
      case "bio4":
        return [value, "биомаркер 4"];
      default:
        return [value, ""];
    }
  };

  return (
    <ResponsiveContainer width="100%">
      <LineChart
        width={400}
        height={300}
        data={data}
        margin={{
          top: 0,
          right: 10,
          left: 10,
          bottom: 0,
        }}
      >
        <Tooltip
          labelFormatter={(t) => data[t].name}
          formatter={(value, name, props) => formatTooltip(value, name, props)}
        />
        <Legend
          align="right"
          iconType="circle"
          payload={[
            { value: "Биомаркер 1", type: "circle", color: "#1E4A9C", id: "bio1" },
            { value: "Биомаркер 2", type: "circle", color: "#34B53A", id: "bio2" },
            { value: "Биомаркер 3", type: "circle", color: "#1E4A9C", id: "bio3" },
            { value: "Биомаркер 4", type: "circle", color: "#F25A3C", id: "bio4" },
          ]}
        />
        <Line type="monotone" dataKey="bio1" stroke="#34B53A" />
        <Line type="monotone" dataKey="bio2" stroke="#1E4A9C" />
        <Line type="monotone" dataKey="bio3" stroke="#1E4A9C" />
        <Line type="monotone" dataKey="bio4" stroke="#F25A3C" />
      </LineChart>
    </ResponsiveContainer>
  );
};

export const BiomarkersChart = () => {
  return (
    <div className={classes.biomarkers}>
      <div className={classes.biomarkersHeader}>
        <div>
          <h3>Избранные биомаркеры</h3>
        </div>
      </div>
      <Chart />
    </div>
  );
};
