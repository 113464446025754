export enum Paths {
  Root = "/",
  Main = "/profile/index",
  Logout = "/logout",
  AppleSuccess = "/apple/success",
  AppleSuccessBind = "/apple/success/bind",
  AppleSuccessBindDelete = "/apple/success/bind-delete",
  AppleSuccessRegister = "/apple/success/register",
  AppleError = "/apple/error",
  AppleUserNotExist = "/apple/error/not-exist",
  RegisterStepper = "/register/quiz",
  ForgotPassword = "/forgot/password",
  ChangeEmail = "/change/email",
  Profile = "/profile",
  Demo = "/demo",
  Dashboard = "/dashboard",
  DashboardPatient = "/patient",
}

export enum InputTypes {
  Text = "text",
  Pass = "password",
  Number = "number",
}

export enum InputStatus {
  None = "None",
  Success = "success",
  Error = "error",
  Warning = "warning",
}

export enum LoadingStatus {
  None = "None",
  Pending = "Pending",
  Success = "Success",
  Error = "Error",
}

export enum SocialLoginTypes {
  Apple = "apple",
  Facebook = "facebook",
}

export enum ConfirmErrorMessage {
  AttemptsExhausted = "Кол-во попыток ввода кода исчерпано",
}

export enum ResetPassErrors {
  Title = "Произошла ошибка!",
  InvalidCode = "Неверный код авторизации",
  InvalidPass = "Вы ввели некорректный пароль",
  AttemptsExhausted = "Кол-во попыток ввода кода исчерпано",
}

export enum StepperType {
  EmailRegister = "EmailRegister",
  FacebookRegister = "FacebookRegister",
  AppleRegister = "AppleRegister",
}
