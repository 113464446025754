import React from "react";
import ReactDOM from "react-dom/client";
import "antd/dist/antd.css";
import "./assets/fonts.css";
import "./index.css";
import App from "./App";


import reportWebVitals from "./reportWebVitals";
import { store } from "@redux/store";
import { Provider } from "react-redux";

import { ConfigProvider } from "antd";
import ru_RU from "antd/es/locale/ru_RU";
import moment from "moment";

import "moment/locale/ru";


moment.locale("ru");

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <ConfigProvider locale={ru_RU}>
      <Provider store={store}>
        <App />
      </Provider>
    </ConfigProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
