import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LoadingStatus } from "@types";
import {
  checkSocialBindsAPI,
  deleteBindAPI,
  getUserProfileAPI,
  setBindAPI,
  saveUserProfileAPI,
  changeEmailAPI,
  attachEmailAPI,
  uploadDemoAPI,
  createDemoProfileAPI,
} from "./api";
import { setCookie } from "../auth/utils";
import { DeleteSocialBind, SetSocialBind } from "./types";

interface ProfileState {
  checkBindStatus: LoadingStatus;
  setSocialBindStatus: LoadingStatus;
  checkBind: Array<string> | [];
  deleteSocialBindStatus: LoadingStatus;
  deleteSocialBindErrorMessage: string;
  getUserProfileStatus: LoadingStatus;
  saveUserProfileStatus: LoadingStatus;
  setSocialBindErrorMessage: string;
  userProfile: any;
  changeEmailStatus: LoadingStatus;
  attachEmailStatus: LoadingStatus;
  uploadDemoStatus: LoadingStatus;
  createDemoAccount: LoadingStatus;
}

const initialState: ProfileState = {
  checkBindStatus: LoadingStatus.None,
  checkBind: [],
  setSocialBindStatus: LoadingStatus.None,
  deleteSocialBindStatus: LoadingStatus.None,
  deleteSocialBindErrorMessage: "",
  setSocialBindErrorMessage: "",
  getUserProfileStatus: LoadingStatus.None,
  saveUserProfileStatus: LoadingStatus.None,
  userProfile: null,
  changeEmailStatus: LoadingStatus.None,
  attachEmailStatus: LoadingStatus.None,
  uploadDemoStatus: LoadingStatus.None,
  createDemoAccount: LoadingStatus.None,
};

export const checkSocialBind = createAsyncThunk("profile/checkSocialBind", async (token: string) => {
  const response = await checkSocialBindsAPI(token);
  return response.data;
});

export const setSocialBind = createAsyncThunk("profile/setSocialBind", async (data: SetSocialBind, thunkAPI) => {
  try {
    const response = await setBindAPI(data);
    if (response.data.result === false) {
      return thunkAPI.rejectWithValue(response.data.message);
    }
    return response.data;
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e.data.message);
  }
});

export const deleteSocialBind = createAsyncThunk(
  "profile/deleteSocialBind",
  async (data: DeleteSocialBind, thunkAPI) => {
    try {
      const response = await deleteBindAPI(data);
      if (response.data.result === false) {
        return thunkAPI.rejectWithValue(response.data.message);
      }
      return response.data;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response?.data.message);
    }
  }
);

export const getUserProfile = createAsyncThunk("profile/getUser", async (token: string) => {
  const response = await getUserProfileAPI(token);
  return response.data;
});

export const saveUserProfile = createAsyncThunk("profile/saveUser", async (d: any) => {
  const { data, token } = d;
  const response = await saveUserProfileAPI(data, token);
  return response.data;
});

export const changeEmail = createAsyncThunk("profile/changeEmail", async (data: any, thunkAPI) => {
  const { email, token } = data;
  try {
    const response = await changeEmailAPI(email, token);
    if (response.data.status === 455) {
      return thunkAPI.rejectWithValue(response.data.message);
    }
    return response.data;
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e.response?.data.message);
  }
});

export const attachEmail = createAsyncThunk("profile/attachEmail", async (data: any) => {
  const { email, token } = data;
  const response = await attachEmailAPI(email, token);
  return response.data;
});

export const uploadDemo = createAsyncThunk("profile/uploadDemo", async (data: any) => {
  const { file, token } = data;
  const response = await uploadDemoAPI(file, token);
  return response.data;
});

export const createDemo = createAsyncThunk("profile/createDemoAccount", async (thunkA) => {
  const response = await createDemoProfileAPI();

  const { session_id, session_name, auth_key } = response.data;
  localStorage.setItem("biodata_key", auth_key);
  setCookie(session_name, session_id, {
    secure: true,
    domain: process.env.REACT_APP_COOKIE_DOMAIN,
    SameSite: "None",
    "max-age": 3600,
  });

  return response.data;
});

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    clearChangeEmalStatus(state) {
      state.changeEmailStatus = LoadingStatus.None;
    }
  },
  extraReducers: {
    [checkSocialBind.pending.type]: (state: ProfileState, { payload }) => {
      state.checkBindStatus = LoadingStatus.Pending;
    },
    [checkSocialBind.fulfilled.type]: (state: ProfileState, { payload }) => {
      state.checkBindStatus = LoadingStatus.Success;
      state.checkBind = payload.socialList;
    },
    [checkSocialBind.rejected.type]: (state: ProfileState, { payload }) => {
      state.checkBindStatus = LoadingStatus.Error;
    },

    [setSocialBind.pending.type]: (state: ProfileState, { payload }) => {
      state.setSocialBindStatus = LoadingStatus.Pending;
    },
    [setSocialBind.fulfilled.type]: (state: ProfileState, { payload }) => {
      state.setSocialBindStatus = LoadingStatus.Success;
    },
    [setSocialBind.rejected.type]: (state: ProfileState, { payload }) => {
      state.setSocialBindStatus = LoadingStatus.Error;
      state.setSocialBindErrorMessage = payload;
    },
    [deleteSocialBind.pending.type]: (state: ProfileState, { payload }) => {
      state.deleteSocialBindStatus = LoadingStatus.Pending;
    },
    [deleteSocialBind.fulfilled.type]: (state: ProfileState, { payload }) => {
      state.deleteSocialBindStatus = LoadingStatus.Success;
    },
    [deleteSocialBind.rejected.type]: (state: ProfileState, { payload }) => {
      state.deleteSocialBindStatus = LoadingStatus.Error;
      state.deleteSocialBindErrorMessage = payload;
    },
    [getUserProfile.pending.type]: (state: ProfileState, { payload }) => {
      state.getUserProfileStatus = LoadingStatus.Pending;
    },
    [getUserProfile.fulfilled.type]: (state: ProfileState, { payload }) => {
      state.getUserProfileStatus = LoadingStatus.Success;
      state.userProfile = payload;
    },
    [getUserProfile.rejected.type]: (state: ProfileState, { payload }) => {
      state.getUserProfileStatus = LoadingStatus.Error;
    },

    [saveUserProfile.pending.type]: (state: ProfileState, { payload }) => {
      state.saveUserProfileStatus = LoadingStatus.Pending;
    },
    [saveUserProfile.fulfilled.type]: (state: ProfileState, { payload }) => {
      state.saveUserProfileStatus = LoadingStatus.Success;
    },
    [saveUserProfile.rejected.type]: (state: ProfileState, { payload }) => {
      state.saveUserProfileStatus = LoadingStatus.Error;
    },

    [changeEmail.pending.type]: (state: ProfileState, { payload }) => {
      state.changeEmailStatus = LoadingStatus.Pending;
    },
    [changeEmail.fulfilled.type]: (state: ProfileState, { payload }) => {
      state.changeEmailStatus = LoadingStatus.Success;
    },
    [changeEmail.rejected.type]: (state: ProfileState, { payload }) => {
      state.changeEmailStatus = LoadingStatus.Error;
    },

    [attachEmail.pending.type]: (state: ProfileState, { payload }) => {
      state.attachEmailStatus = LoadingStatus.Pending;
    },
    [attachEmail.fulfilled.type]: (state: ProfileState, { payload }) => {
      state.attachEmailStatus = LoadingStatus.Success;
    },
    [attachEmail.rejected.type]: (state: ProfileState, { payload }) => {
      state.attachEmailStatus = LoadingStatus.Error;
    },

    [uploadDemo.pending.type]: (state: ProfileState, { payload }) => {
      state.uploadDemoStatus = LoadingStatus.Pending;
    },
    [uploadDemo.fulfilled.type]: (state: ProfileState, { payload }) => {
      state.uploadDemoStatus = LoadingStatus.Success;
    },
    [uploadDemo.rejected.type]: (state: ProfileState, { payload }) => {
      state.uploadDemoStatus = LoadingStatus.Error;
    },

    [createDemo.pending.type]: (state: ProfileState, { payload }) => {
      state.createDemoAccount = LoadingStatus.Pending;
    },
    [createDemo.fulfilled.type]: (state: ProfileState, { payload }) => {
      state.createDemoAccount = LoadingStatus.Success;
    },
    [createDemo.rejected.type]: (state: ProfileState, { payload }) => {
      state.createDemoAccount = LoadingStatus.Error;
    },
  },
});

export default profileSlice.reducer;
export const { clearChangeEmalStatus } = profileSlice.actions;
