import React, { useEffect } from "react";
import { Button, notification } from "antd";

interface Props {
  title: string;
  message: string;
  icon?: any;
  isOpen: boolean;
  onClose: () => void;
  duration: number;
}

export const Notification: React.FC<Props> = ({ isOpen, title, message, icon, duration, onClose }) => {
  useEffect(() => {
    if (isOpen) {
      notification.open({
        message: title,
        description: message,
        icon: icon,
        onClose: onClose,
        duration,
      });
    }
  }, [isOpen]);

  return <></>;
};
