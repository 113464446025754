import React from "react";
import "./style.css";

export const LegacyLogo = () => {
  return (
    <svg
      version="1.1"
      id="Слой_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 410.1 383"
      // style="enable-background:new 0 0 410.1 383;"
      xmlSpace="preserve"
    >
      <rect y="163.4" className="st0" width="40.2" height="134.1" />
      <rect x="61.2" y="223.2" className="st0" width="40.2" height="71.9" />
      <rect x="122.4" y="193.9" className="st0" width="40.2" height="101.2" />
      <rect x="184.9" y="130.5" className="st0" width="40.2" height="164.6" />
      <rect x="247.3" y="79.3" className="st0" width="40.2" height="215.9" />
      <rect x="308.5" className="st0" width="40.2" height="295.1" />
      <rect x="370.1" y="101.2" className="st0" width="40.2" height="193.9" />
      <g className="st1">
        <path
          className="st0"
          d="M8.1,321.1v19.6l3.7-2.9c2.3-1.9,4.7-2.9,7.2-2.9h7.7c2.8,0,5.3,1,7.5,2.9l4,3.4c1.1,1,2,2.3,2.7,4.1
		c0.7,1.8,1.1,3.5,1.1,5.3v16.9c0,1.5-0.3,3.1-1,4.8c-0.7,1.7-1.4,2.9-2.3,3.7l-3.6,3.4c-0.9,0.9-2.3,1.7-4,2.4
		c-1.7,0.7-3.2,1.1-4.4,1.1h-7.2c-3.5,0-7.3-1.9-11.4-5.8v1.6c0,1.2-0.4,2.1-1.2,3c-0.8,0.8-1.8,1.2-3,1.2c-1.2,0-2.1-0.4-3-1.2
		c-0.8-0.8-1.2-1.8-1.2-3v-57.7c0-1.2,0.4-2.1,1.2-3c0.8-0.8,1.8-1.2,2.9-1.2c1.2,0,2.1,0.4,3,1.2C7.7,319,8.1,320,8.1,321.1z
		 M26,343.4h-5.8c-1.4,0-2.5,0.3-3.2,0.9l-7.5,6.4c-1,0.9-1.4,2.2-1.4,3.9v9.4c0,1.4,0.5,2.6,1.6,3.5l6.7,5.7c1,0.9,2,1.3,3,1.3h6.8
		c1,0,1.8-0.3,2.5-0.9l3.3-3c0.9-0.7,1.3-1.6,1.3-2.8v-17.6c0-1.4-0.4-2.5-1.3-3.2l-3-2.5C28.1,343.8,27.1,343.4,26,343.4z"
        />
        <path
          className="st0"
          d="M94.7,382.9H69.2c-1.2,0-2.1-0.4-3-1.2c-0.8-0.8-1.2-1.8-1.2-3c0-1.1,0.4-2.1,1.2-2.9c0.8-0.8,1.8-1.2,3-1.2
		h8.5v-31.1h-8.4c-1.2,0-2.1-0.4-3-1.2c-0.8-0.8-1.2-1.8-1.2-2.9c0-1.2,0.4-2.2,1.2-3c0.8-0.8,1.8-1.3,3-1.3H82c1.2,0,2.1,0.4,3,1.3
		c0.8,0.8,1.2,1.8,1.2,3v35.3h8.3c1.2,0,2.2,0.4,3,1.2c0.8,0.8,1.3,1.8,1.3,3c0,1.2-0.4,2.1-1.2,3C96.8,382.5,95.8,382.9,94.7,382.9
		z M77.7,313.3H82c1.2,0,2.1,0.4,3,1.2c0.8,0.8,1.2,1.8,1.2,3v3.6c0,1.2-0.4,2.2-1.2,3c-0.8,0.8-1.8,1.3-3,1.3h-4.5
		c-1.2,0-2.2-0.4-3-1.3c-0.8-0.9-1.2-1.8-1.2-2.9l0.1-3.7c0-1.2,0.4-2.1,1.2-3C75.5,313.7,76.5,313.3,77.7,313.3z"
        />
        <path
          className="st0"
          d="M164.5,350.1v17.6c0,3.7-1.8,7.2-5.3,10.4c-3.5,3.2-7.2,4.8-10.9,4.8h-9.8c-3.8,0-7.5-1.6-10.9-4.8
		c-3.5-3.2-5.2-6.7-5.2-10.3v-17.6c0-3.6,1.7-7.1,5.1-10.3c3.4-3.2,7.1-4.8,11.1-4.8h9.8c3.7-0.1,7.4,1.5,10.9,4.7
		S164.5,346.3,164.5,350.1z M130.8,350.4v16.9c0,1.5,0.5,2.7,1.6,3.6l3.1,2.7c0.7,0.6,1.7,0.9,2.9,0.9h9.7c1.4,0,2.7-0.5,3.9-1.5
		l2.5-2.2c1-0.8,1.5-1.9,1.5-3.3v-16.9c0-1.7-0.5-2.9-1.5-3.7l-2.9-2.3c-1-0.8-2.2-1.2-3.5-1.2h-8.6c-1.9,0-3.3,0.4-4.4,1.3
		l-2.8,2.4C131.3,347.9,130.8,349.1,130.8,350.4z"
        />
        <path
          className="st0"
          d="M217.5,340.9v-19.7c0-1.2,0.4-2.1,1.2-3s1.8-1.2,2.9-1.2c1.2,0,2.2,0.4,3,1.2s1.3,1.8,1.3,3v57.7
		c0,1.2-0.4,2.1-1.2,3s-1.8,1.2-2.9,1.2c-1.2,0-2.2-0.4-3-1.2s-1.2-1.8-1.2-3V377c-4.1,4-7.9,6-11.4,6h-7.1c-2.8,0-5.7-1.2-8.5-3.7
		l-3.5-2.9c-0.8-0.8-1.5-2-2.3-3.8c-0.7-1.8-1.1-3.5-1.1-5v-17c0-1.9,0.4-3.7,1.2-5.5c0.8-1.8,1.7-3.1,2.8-4l3.4-2.9
		c2.5-2.1,5.3-3.2,8.6-3.2h6c3.3,0,6.3,1.2,8.9,3.5L217.5,340.9z M205.3,343.4h-5.8c-1.2,0-2.3,0.4-3.3,1.3l-2.7,2.3
		c-0.9,0.8-1.4,1.9-1.4,3.2v17.7c0,1.2,0.5,2.2,1.6,3.1l2.7,2.3c0.9,0.8,1.8,1.1,2.7,1.1h6.8c0.9,0,2-0.5,3.2-1.5l6.6-5.5
		c1.1-1,1.6-2.2,1.6-3.6v-9c0-2-0.6-3.5-1.7-4.5l-6.8-5.8C208,343.8,206.8,343.4,205.3,343.4z"
        />
        <path
          className="st0"
          d="M257.8,352.9h16.4c0.9,0,2.2,0.2,3.9,0.6l-0.1-5.9c0-1.2-0.4-2.1-1.2-3c-0.8-0.8-1.8-1.2-3-1.2h-15.9
		c-1.1,0-2.1-0.4-2.9-1.2s-1.2-1.8-1.2-2.9c0-1.2,0.4-2.1,1.2-3c0.8-0.8,1.8-1.2,3-1.2h15.9c3.5,0,6.4,1.2,8.8,3.6
		c2.4,2.4,3.7,5.4,3.8,8.9l1,31.2c0,1.2-0.4,2.1-1.2,3c-0.8,0.8-1.8,1.2-2.9,1.2c-1.2,0-2.2-0.4-3-1.2s-1.2-1.8-1.2-3l-7.1,4.2
		h-14.1c-3.5,0-6.5-1.2-9-3.7c-2.5-2.5-3.7-5.4-3.7-8.9v-4.9c0-3.5,1.2-6.4,3.7-8.9C251.3,354.1,254.3,352.9,257.8,352.9z
		 M278.6,369.1l-0.2-3.5c0-1.2-0.4-2.2-1.2-3c-0.8-0.8-1.8-1.2-2.9-1.2h-16.5c-1.2,0-2.1,0.4-3,1.2c-0.8,0.8-1.2,1.8-1.2,3v4.7
		c0,1.1,0.4,2.1,1.2,2.9c0.8,0.8,1.8,1.2,2.9,1.2h11.8L278.6,369.1z"
        />
        <path
          className="st0"
          d="M336.1,383h-8.3c-3.4,0-6.4-1.2-8.9-3.7c-2.5-2.5-3.7-5.4-3.7-8.8v-27.1h-4.3c-1.2,0-2.1-0.4-3-1.2
		c-0.8-0.8-1.2-1.8-1.2-3c0-1.2,0.4-2.1,1.2-3c0.8-0.8,1.8-1.2,3-1.2h4.4v-10.2c0-1.2,0.4-2.1,1.2-3c0.8-0.8,1.8-1.2,3-1.2
		c1.1,0,2.1,0.4,2.9,1.2c0.8,0.8,1.3,1.8,1.3,2.9V335h16.8c1.1,0,2.1,0.4,2.9,1.2s1.2,1.8,1.2,2.9c0,1.2-0.4,2.1-1.2,3
		s-1.8,1.2-2.9,1.2h-16.9v27.1c0,1.1,0.4,2.1,1.3,2.9c0.8,0.8,1.8,1.2,3,1.2h8.1c2.2,0,3.7-1.2,4.4-3.6c0.7-2.4,2.1-3.6,4.2-3.6
		c1.2,0,2.2,0.4,3,1.2c0.8,0.8,1.2,1.8,1.2,3.1c0,2.5-1.2,5-3.6,7.5C342.8,381.7,339.8,383,336.1,383z"
        />
        <path
          className="st0"
          d="M380.7,352.9h16.4c0.9,0,2.2,0.2,3.9,0.6l-0.1-5.9c0-1.2-0.4-2.1-1.2-3c-0.8-0.8-1.8-1.2-3-1.2h-15.9
		c-1.1,0-2.1-0.4-2.9-1.2s-1.2-1.8-1.2-2.9c0-1.2,0.4-2.1,1.2-3c0.8-0.8,1.8-1.2,3-1.2h15.9c3.5,0,6.4,1.2,8.8,3.6
		c2.4,2.4,3.7,5.4,3.8,8.9l1,31.2c0,1.2-0.4,2.1-1.2,3c-0.8,0.8-1.8,1.2-2.9,1.2c-1.2,0-2.2-0.4-3-1.2s-1.2-1.8-1.2-3l-7.1,4.2
		h-14.1c-3.5,0-6.5-1.2-9-3.7c-2.5-2.5-3.7-5.4-3.7-8.9v-4.9c0-3.5,1.2-6.4,3.7-8.9C374.2,354.1,377.2,352.9,380.7,352.9z
		 M401.6,369.1l-0.2-3.5c0-1.2-0.4-2.2-1.2-3c-0.8-0.8-1.8-1.2-2.9-1.2h-16.5c-1.2,0-2.1,0.4-3,1.2c-0.8,0.8-1.2,1.8-1.2,3v4.7
		c0,1.1,0.4,2.1,1.2,2.9c0.8,0.8,1.8,1.2,2.9,1.2h11.8L401.6,369.1z"
        />
      </g>
    </svg>
  );
};
