import AppleLogin from "react-apple-login";
import { useNavigate } from "react-router-dom";
import { AppleBlockView } from "./AppleBlockView";

interface Props {
  type: string;
  isBind?: boolean;
  isDisable?: boolean;
  bindStatus?: boolean;
}

export const Redirects = {
  Login: `https://account.${process.env.REACT_APP_COOKIE_DOMAIN}/site/apple-success`,
  Register: `https://account.${process.env.REACT_APP_COOKIE_DOMAIN}/site/apple-success-register`,
  SetBind: `https://account.${process.env.REACT_APP_COOKIE_DOMAIN}/site/apple-success-bind`,
  DeleteBind: `https://account.${process.env.REACT_APP_COOKIE_DOMAIN}/site/apple-success-bind-delete`,
};

export const AppleBlock: React.FC<Props> = ({ type, isBind, isDisable, bindStatus }) => {
  const navigate = useNavigate();

  return (
    <AppleLogin
      clientId={"com.app.biodata.group"}
      redirectURI={type}
      responseType={"code id_token"}
      responseMode={"form_post"}
      usePopup={false}
      scope={"name email"}
      callback={(d) => {
        navigate("/success");
      }}
      render={({ onClick, disabled }) => (
        <AppleBlockView
          handleClick={!isDisable ? onClick : () => {}}
          isBind={isBind}
          bindStatus={bindStatus}
          isDisable={isDisable}
        />
      )}
      designProp={{
        height: 30,
        width: 140,
        color: "black",
        border: false,
        type: "sign-in",
        border_radius: 15,
        scale: 1,
        locale: "en_US",
      }}
    />
  );
};
