import React, { ChangeEvent } from "react";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import cn from "classnames";
import { Input } from "antd";
import classes from "./Textfield.module.scss";
import { InputTypes, InputStatus } from "@types";

const { Password } = Input;

interface Props {
  placeholder?: string;
  value: string;
  handleChange: (e: ChangeEvent<HTMLInputElement>, type?: any) => void;
  keyId?: any;
  disabled?: boolean;
  validate?: boolean;
  type?: InputTypes;
  status?: InputStatus;
  errorMessage?: string;
  className?: string;
  maxLength?: number;
}

export const Textfield: React.FC<Props> = ({
  placeholder,
  value,
  handleChange,
  keyId,
  disabled,
  type,
  status,
  className,
  maxLength,
  errorMessage,
}) => {
  switch (type) {
    case InputTypes.Pass:
      return (
        <Password
          placeholder={placeholder}
          className={cn(
            classes.textfield,
            className,
            status === InputStatus.Error && classes.error,
            status === InputStatus.Warning && classes.warning
          )}
          onChange={keyId ? (e) => handleChange(e, keyId) : (e) => handleChange(e)}
          disabled={disabled}
          type={type}
          value={value}
          maxLength={maxLength}
          iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
        />
      );
    default:
      return (
        <Input
          placeholder={placeholder}
          className={cn(
            classes.textfield,
            className,
            status === InputStatus.Error && classes.error,
            status === InputStatus.Warning && classes.warning
          )}
          onChange={keyId ? (e) => handleChange(e, keyId) : (e) => handleChange(e)}
          disabled={disabled}
          type={type}
          value={value}
          maxLength={maxLength}
        />
      );
  }
};
