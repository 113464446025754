import React from "react";
import cn from "classnames";
import classes from "./style.module.scss";
import { Apple } from "@icons";

interface Props {
  handleClick: (e: any) => void;
  isBind?: boolean;
  isDisable?: boolean;
  bindStatus?: boolean;
}

export const AppleBlockView: React.FC<Props> = ({ handleClick, isBind, isDisable, bindStatus }) => {
  return (
    <div className={cn(classes.authSocial, isDisable && classes.authSocialDisabled)} onClick={handleClick}>
      <Apple />
      <span>Apple ID</span>
      {isBind && !isDisable && !bindStatus && <a className={classes.socialBindLabel}>Подключить</a>}
      {isBind && !isDisable && bindStatus && <a className={classes.socialBindLabelRed}>Отключить</a>}
    </div>
  );
};
