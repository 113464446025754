import { ChangeEvent } from "react";

export enum Tabs {
  Login = "Login",
  Register = "Register",
}

export enum FacebookTypes {
  Login = "Login",
  Register = "Register",
  SetBind = "Bind",
  DeleteBind = "DeleteBind",
}

export enum RegisterType {
  None = "None",
  Email = "Email",
  Social = "Social",
}

export interface TabsProps {
  handleClick: (tab: Tabs) => void;
  tab: Tabs;
}

export enum AuthInput {
  Email = "username",
  Pass = "password",
}

export enum Sex {
  Male = "Male",
  Female = "Female",
}

export interface InitialAuth {
  [key: string]: string;
}

export interface InitialAuthValidate {
  [key: string]: boolean;
}

export const initialAuth = {
  [AuthInput.Email]: "",
  [AuthInput.Pass]: "",
};

export const initialAuthValidate = {
  [AuthInput.Email]: true,
  [AuthInput.Pass]: true,
};

export enum RegisterErrorMessages {
  AlreadyExist = "Пользователь с таким e-mail уже зарегистрирован.",
}

export enum LoginErrorMessages {
  WrongCredentinals = "Имя пользователя или пароль неверны.",
}

export interface LoginTabProps {
  handleChange: (e: ChangeEvent<HTMLInputElement>, keyId: AuthInput) => void;
  auth: any;
  authTouched: boolean;
  authValidate: any;
  handleAuth: () => void;
}

export enum ErrorMessages {
  Title = "Произошла ошибка!",
  Common = "Попробуйте повторить операцию.",
  TooShortPass = "Пароль должен быть больше 6 символов!",
  AlreadyExist = "Такой пользователь уже существует!",
  FinalBind = "Этот способ авторизации является единственным для данного аккаунта. Если вы его отключите то потеряете доступ к своим данным!",
  BindAlreadyExist = 'Эта социальная сеть уже привязана к другому аккаунту!',
  EmailAlreadyExist = 'Данный email уже зарегистрирован в системе'
}
