import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LoadingStatus, SocialLoginTypes } from "@types";
import {
  loginAPI,
  registerAPI,
  loginSocialAPI,
  emailConfirmCodeAPI,
  sendEmailConfirmAPI,
  editUserInfoAPI,
  fetchGoalsAPI,
  editGoalsAPI,
  registerSocialAPI,
  resetPasswordAPI,
  approveNewPasswordAPI,
  createSessionAPI,
  checkGoalsAlreadyExistAPI,
} from "./api";
import {
  Login,
  User,
  Register,
  LoginSocial,
  ConfirmResult,
  SendEmailToConfirm,
  ConfirmEmailByCode,
  UserInfo,
  ApproveNewPassword,
} from "./types";
import { setCookie } from "./utils";

export interface AuthState {
  loginStatus: LoadingStatus;
  inputUsername: string;
  inputPassword: string;
  forgotEmail: string;
  user: User | null;
  loginSocialStatus: LoadingStatus;
  registerStatus: LoadingStatus;
  registerSocialStatus: LoadingStatus;
  sendEmailConfirmStatus: LoadingStatus;
  confirmStatus: LoadingStatus;
  confirm: ConfirmResult | null;
  confirmEmailMessage: string;
  editUserStatus: LoadingStatus;
  fetchGoalsStatus: LoadingStatus;
  goals: Array<any>;
  editGoalsStatus: LoadingStatus;
  signUpErrorMessage: string | null;
  signInErrorMessage: string | null;
  resetPasswordRequestStatus: LoadingStatus;
  approvePasswordMessage: string;
  approveNewPasswordStatus: LoadingStatus;
  registerSocialErrorMessage: string;
  facebookRegisterToken: string;
  appleRegisterToken: string;
  token: string;
  createSessionStatus: LoadingStatus;
  checkGoalsAlreadyExistStatus: LoadingStatus;
  checkGoalsAlreadyExist: [];
}

const initialState: AuthState = {
  loginStatus: LoadingStatus.None,
  inputUsername: "",
  inputPassword: "",
  forgotEmail: "",
  user: null,
  loginSocialStatus: LoadingStatus.None,
  registerStatus: LoadingStatus.None,
  registerSocialStatus: LoadingStatus.None,
  sendEmailConfirmStatus: LoadingStatus.None,
  confirmStatus: LoadingStatus.None,
  confirm: null,
  confirmEmailMessage: "",
  editUserStatus: LoadingStatus.None,
  fetchGoalsStatus: LoadingStatus.None,
  goals: [],
  editGoalsStatus: LoadingStatus.None,
  signUpErrorMessage: null,
  signInErrorMessage: null,
  resetPasswordRequestStatus: LoadingStatus.None,
  approvePasswordMessage: "",
  approveNewPasswordStatus: LoadingStatus.None,
  facebookRegisterToken: "",
  appleRegisterToken: "",
  registerSocialErrorMessage: "",
  token: "",
  createSessionStatus: LoadingStatus.None,

  checkGoalsAlreadyExistStatus: LoadingStatus.None,
  checkGoalsAlreadyExist: [],
};

export const login = createAsyncThunk("auth/login", async (data: Login, thunkAPI) => {
  const { username, password, isOriginalLogin } = data;
  try {
    const response = await loginAPI({ username, password });
    thunkAPI.dispatch(setAuthParams({ username, password }));
    const { session_id, session_name } = response.data;

    if (response.data.result === false) {
      return thunkAPI.rejectWithValue(response.data[0].message);
    }
    if (isOriginalLogin) {
      setCookie(session_name, session_id, {
        secure: true,
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
        SameSite: "None",
        "max-age": 36000000,
      });
    }

    return response.data;
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e.response.data[0].message);
  }
});

export const sendEmailConfirm = createAsyncThunk("auth/sendEmailConfirm", async (data: SendEmailToConfirm) => {
  const response = await sendEmailConfirmAPI(data);
  return response.data;
});

export const createSession = createAsyncThunk("auth/createSession", async (token: string) => {
  const response = await createSessionAPI(token);
  const { session_id, session_name } = response.data;
  setCookie(session_name, session_id, {
    secure: true,
    // domain: "testzone51.ru",
    domain: process.env.REACT_APP_COOKIE_DOMAIN,
    SameSite: "None",
    "max-age": 3600,
  });
  return response.data;
});

export const confirmEmail = createAsyncThunk("auth/confirmEmail", async (data: ConfirmEmailByCode, thunkAPI) => {
  try {
    const response = await emailConfirmCodeAPI(data);
    return response.data;
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e.response.data.message);
  }
});

export const register = createAsyncThunk("auth/register", async (data: Register, thunkAPI) => {
  try {
    const { email, password } = data;
    thunkAPI.dispatch(setAuthParams({ username: email, password }));
    const response = await registerAPI(data);
    return response.data;
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e.response?.data[0].message);
  }
});

export const loginSocial = createAsyncThunk("auth/apple", async (data: LoginSocial) => {
  const response = await loginSocialAPI(data);
  const { session_id, session_name } = response.data;
  setCookie(session_name, session_id, {
    secure: true,
    // domain: "testzone51.ru",
    domain: process.env.REACT_APP_COOKIE_DOMAIN,
    SameSite: "None",
    "max-age": 3600,
  });
  return response.data;
});

export const registerSocial = createAsyncThunk("auth/register/apple", async (data: LoginSocial, thunkAPI) => {
  const response = await registerSocialAPI(data);
  if (response.data.result === false) {
    return thunkAPI.rejectWithValue(response?.data.message);
  } else {
    const { session_id, session_name } = response.data;
    setCookie(session_name, session_id, {
      secure: true,
      //domain: "testzone51.ru",
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      SameSite: "None",
      "max-age": 3600,
    });
    // if (data.socialName === SocialLoginTypes.Apple) {
    //   thunkAPI.dispatch(setAppleRegisterToken({ token: data.socialToken }));
    // } else {
    //   thunkAPI.dispatch(setFacebookRegisterToken({ token: data.socialToken }));
    // }
    return response.data;
  }
});

export const editUser = createAsyncThunk("auth/editUser", async (data: { info: UserInfo; token: string }) => {
  const { info, token } = data;
  const response = await editUserInfoAPI(info, token);
  return response.data;
});

export const fetchGoals = createAsyncThunk("auth/fetchGoals", async (token: string) => {
  const response = await fetchGoalsAPI(token);
  return response.data.targets;
});

export const editGoals = createAsyncThunk("auth/editGoals", async (data: { goals: Array<string>; token: string }) => {
  const { token, goals } = data;
  const response = await editGoalsAPI(goals, token);
  return response.data;
});

export const resetPasswordRequest = createAsyncThunk("auth/resetPasswordRequest", async (data: { email: string }) => {
  const { email } = data;
  const response = await resetPasswordAPI(email);
  return response.data;
});

export const approveNewPassword = createAsyncThunk(
  "auth/approveNewPassword",
  async (data: ApproveNewPassword, thunkAPI) => {
    try {
      const response = await approveNewPasswordAPI(data);
      return response.data;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const checkGoalsAlreadyExist = createAsyncThunk("auth/checkGoalsAlreadyExist", async (token: string) => {
  const response = await checkGoalsAlreadyExistAPI(token);
  return response.data;
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearRegisterStatus(state) {
      state.registerStatus = LoadingStatus.None;
    },
    clearLoginStatus(state) {
      state.loginStatus = LoadingStatus.None;
    },
    setAuthParams(state, { payload }) {
      const { username, password } = payload;
      state.inputUsername = username;
      state.inputPassword = password;
    },
    setEmail(state, { payload }) {
      const { email } = payload;
      state.forgotEmail = email;
    },
    setAuthToken(state, { payload }) {
      const { token } = payload;
      state.token = token;
    },
    setFacebookRegisterToken(state, { payload }) {
      const { token } = payload;
      state.facebookRegisterToken = token;
    },
    setAppleRegisterToken(state, { payload }) {
      const { token } = payload;
      state.facebookRegisterToken = token;
    },
    logout(state) {
      localStorage.removeItem("biodata_key");
      setCookie("application-frontend", "", {
        expires: -1,
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
      });
    },

    clearConfirmStatus(state) {
      state.confirmStatus = LoadingStatus.None;
    },
  },
  extraReducers: {
    [login.pending.type]: (state: AuthState, { payload }) => {
      state.loginStatus = LoadingStatus.Pending;
    },
    [login.fulfilled.type]: (state: AuthState, { payload }) => {
      state.loginStatus = LoadingStatus.Success;
      state.user = payload;
      localStorage.setItem("biodata_key", payload.auth_key);
    },
    [login.rejected.type]: (state: AuthState, { payload }) => {
      state.loginStatus = LoadingStatus.Error;
      state.signInErrorMessage = payload;
    },
    [register.pending.type]: (state: AuthState, { payload }) => {
      state.registerStatus = LoadingStatus.Pending;
    },
    [register.fulfilled.type]: (state: AuthState, { payload }) => {
      state.registerStatus = LoadingStatus.Success;
      state.user = payload;
    },
    [register.rejected.type]: (state: AuthState, { payload }) => {
      state.registerStatus = LoadingStatus.Error;
      state.signUpErrorMessage = payload;
    },
    [registerSocial.pending.type]: (state: AuthState, { payload }) => {
      state.registerSocialStatus = LoadingStatus.Pending;
    },
    [registerSocial.fulfilled.type]: (state: AuthState, { payload }) => {
      state.registerSocialStatus = LoadingStatus.Success;
      state.user = payload;
      localStorage.setItem("biodata_key", payload.auth_key);
    },
    [registerSocial.rejected.type]: (state: AuthState, { payload }) => {
      state.registerSocialStatus = LoadingStatus.Error;
      state.registerSocialErrorMessage = payload;
    },

    [loginSocial.pending.type]: (state: AuthState, { payload }) => {
      state.loginSocialStatus = LoadingStatus.Pending;
    },

    [loginSocial.fulfilled.type]: (state: AuthState, { payload }) => {
      state.loginSocialStatus = LoadingStatus.Success;
      state.user = payload;
      localStorage.setItem("biodata_key", payload.auth_key);
    },

    [loginSocial.rejected.type]: (state: AuthState, { payload }) => {
      state.loginSocialStatus = LoadingStatus.Error;
    },

    [confirmEmail.pending.type]: (state: AuthState, { payload }) => {
      state.confirmStatus = LoadingStatus.Pending;
    },

    [confirmEmail.fulfilled.type]: (state: AuthState, { payload }) => {
      state.confirmStatus = LoadingStatus.Success;
      state.confirm = payload;
    },

    [confirmEmail.rejected.type]: (state: AuthState, { payload }) => {
      state.confirmStatus = LoadingStatus.Error;
      state.confirmEmailMessage = payload;
    },

    [sendEmailConfirm.pending.type]: (state: AuthState, { payload }) => {
      state.sendEmailConfirmStatus = LoadingStatus.Pending;
    },

    [sendEmailConfirm.fulfilled.type]: (state: AuthState, { payload }) => {
      state.sendEmailConfirmStatus = LoadingStatus.Success;
      state.confirm = payload;
    },

    [sendEmailConfirm.rejected.type]: (state: AuthState, { payload }) => {
      state.sendEmailConfirmStatus = LoadingStatus.Error;
    },
    [editUser.pending.type]: (state: AuthState, { payload }) => {
      state.editUserStatus = LoadingStatus.Pending;
    },

    [editUser.fulfilled.type]: (state: AuthState, { payload }) => {
      state.editUserStatus = LoadingStatus.Success;
    },

    [editUser.rejected.type]: (state: AuthState, { payload }) => {
      state.editUserStatus = LoadingStatus.Error;
    },

    [fetchGoals.pending.type]: (state: AuthState, { payload }) => {
      state.fetchGoalsStatus = LoadingStatus.Pending;
    },

    [fetchGoals.fulfilled.type]: (state: AuthState, { payload }) => {
      state.fetchGoalsStatus = LoadingStatus.Success;
      state.goals = payload;
    },

    [fetchGoals.rejected.type]: (state: AuthState, { payload }) => {
      state.fetchGoalsStatus = LoadingStatus.Error;
    },

    [editGoals.pending.type]: (state: AuthState, { payload }) => {
      state.editGoalsStatus = LoadingStatus.Pending;
    },

    [editGoals.fulfilled.type]: (state: AuthState, { payload }) => {
      state.editGoalsStatus = LoadingStatus.Success;
    },

    [editGoals.rejected.type]: (state: AuthState, { payload }) => {
      state.editGoalsStatus = LoadingStatus.Error;
    },

    [resetPasswordRequest.pending.type]: (state: AuthState, { payload }) => {
      state.resetPasswordRequestStatus = LoadingStatus.Pending;
    },

    [resetPasswordRequest.fulfilled.type]: (state: AuthState, { payload }) => {
      state.resetPasswordRequestStatus = LoadingStatus.Success;
    },

    [resetPasswordRequest.rejected.type]: (state: AuthState, { payload }) => {
      state.resetPasswordRequestStatus = LoadingStatus.Error;
    },

    [approveNewPassword.pending.type]: (state: AuthState, { payload }) => {
      state.approveNewPasswordStatus = LoadingStatus.Pending;
    },

    [approveNewPassword.fulfilled.type]: (state: AuthState, { payload }) => {
      state.approveNewPasswordStatus = LoadingStatus.Success;
    },

    [approveNewPassword.rejected.type]: (state: AuthState, { payload }) => {
      state.approveNewPasswordStatus = LoadingStatus.Error;
      state.approvePasswordMessage = payload;
    },

    [createSession.pending.type]: (state: AuthState, { payload }) => {
      state.createSessionStatus = LoadingStatus.Pending;
    },

    [createSession.fulfilled.type]: (state: AuthState, { payload }) => {
      state.createSessionStatus = LoadingStatus.Success;
    },

    [createSession.rejected.type]: (state: AuthState, { payload }) => {
      state.createSessionStatus = LoadingStatus.Error;
      state.createSessionStatus = payload;
    },

    [checkGoalsAlreadyExist.pending.type]: (state: AuthState, { payload }) => {
      state.checkGoalsAlreadyExistStatus = LoadingStatus.Pending;
    },

    [checkGoalsAlreadyExist.fulfilled.type]: (state: AuthState, { payload }) => {
      state.checkGoalsAlreadyExistStatus = LoadingStatus.Success;
    },

    [checkGoalsAlreadyExist.rejected.type]: (state: AuthState, { payload }) => {
      state.checkGoalsAlreadyExistStatus = LoadingStatus.Error;
      state.checkGoalsAlreadyExist = payload;
    },
  },
});

export default authSlice.reducer;
export const {
  clearRegisterStatus,
  clearLoginStatus,
  setAuthParams,
  setEmail,
  setAuthToken,
  logout,
  setFacebookRegisterToken,
  setAppleRegisterToken,
  clearConfirmStatus,
} = authSlice.actions;
