import { configureStore } from "@reduxjs/toolkit";
import { authSlice } from "@redux/modules/auth";
import { profileSlice } from "@redux/modules/profile";

export const store = configureStore({
  reducer: {
    auth: authSlice,
    profile: profileSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
