import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { AppDispatch, RootState } from "@redux/store";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LoadingStatus, Paths, SocialLoginTypes } from "@types";
import { setSocialBind } from "@redux/modules/profile";
import { useAuth } from "@utils/hooks";

export const AppleSuccessBind = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { isAuth, key } = useAuth();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get("data");
  const setSocialBindStatus = useSelector((state: RootState) => state.profile.setSocialBindStatus);
  // const userToken = useSelector((state: RootState) => state.auth.token)
  const userToken = localStorage.getItem("biodata_key") as string;

  useEffect(() => {
    if (token) {
      dispatch(
        setSocialBind({
          socialName: SocialLoginTypes.Apple,
          userToken: userToken,
          socialToken: window.atob(token),
        })
      );
    }
  }, []);

  useEffect(() => {
    if (setSocialBindStatus === LoadingStatus.Success || setSocialBindStatus === LoadingStatus.Error) {
      navigate(Paths.Profile);
    }
  }, [setSocialBindStatus]);

  return <></>;
};
