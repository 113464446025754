import React, { useState, useEffect, SetStateAction, Dispatch } from "react";
import { Textfield, Button } from "@common";
import { InputStatus, LoadingStatus } from "@types";
import classes from "./style.module.scss";
import { RootState, AppDispatch } from "@redux/store";
import { useDispatch, useSelector } from "react-redux";
import { editUser } from "@redux/modules/auth";

interface Props {
  setStepNumber: Dispatch<SetStateAction<number>>;
}

enum UserInfo {
  Name = "name",
  Surname = "surname",
}

const initialUserInfo = {
  [UserInfo.Name]: "",
  [UserInfo.Surname]: "",
};

const initialUserInfoValidate = {
  [UserInfo.Name]: false,
  [UserInfo.Surname]: true,
};

const validateFields = (value: string, keyId: UserInfo) => {
  switch (keyId) {
    case UserInfo.Name:
      return value.length > 2;
    case UserInfo.Surname:
      return true;
  }
};

export const EnterName = ({ setStepNumber }) => {
  const [user, setUser] = useState<any>(initialUserInfo);
  const [validate, setValidate] = useState(initialUserInfoValidate);
  const [touched, setTouched] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const authKey = useSelector(
    (state: RootState) => state.auth.user?.auth_key
  ) as string;

  const email = useSelector((state: RootState) => state.auth.user?.email);
  const editUserStatus = useSelector((state: RootState) => state.auth.editUserStatus);

  useEffect(() => {
    if(editUserStatus === LoadingStatus.Success){
        setStepNumber(2)
    }
  }, [editUserStatus])

  const handleChange = (e: any, key: UserInfo) => {
    const value = e.target.value;
    if (key === UserInfo.Name) {
      setTouched(false);
    }
    setUser({
      ...user,
      [key]: value,
    });

    setValidate({
      ...validate,
      [key]: validateFields(value, key),
    });
  };

  const handleSendData = () => {
    setTouched(true);

    if (validate[UserInfo.Name]) {
      const info = {
        first_name: user[UserInfo.Name],
        last_name: user[UserInfo.Surname],
        email,
        
      };
      dispatch(editUser({ info, token: authKey }));
    }
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <h2 className={classes.title}>Как к вам обращаться?</h2>
        <p className={classes.description}>
          Если вы планируете предоставлять доступ к своим данным врачу, укажите
          имя и фамилию
        </p>
        <div className={classes.userinfoInputs}>
          <Textfield
            value={user[UserInfo.Name]}
            keyId={UserInfo.Name}
            handleChange={handleChange}
            placeholder="Ваше имя"
            status={
              touched && !validate[UserInfo.Name]
                ? InputStatus.Error
                : InputStatus.Success
            }
          />

          <Textfield
            value={user[UserInfo.Surname]}
            handleChange={handleChange}
            keyId={UserInfo.Surname}
            placeholder="Ваша фамилия"
          />
          <Button handleClick={handleSendData} text={"Следующий шаг"} />
        </div>
      </div>
    </div>
  );
};
