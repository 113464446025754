import React, { useState, useEffect, SetStateAction, Dispatch } from "react";
import { RootState, AppDispatch } from "@redux/store";
import { AlertTwoTone } from "@ant-design/icons";
import { Textfield, Button, StrokeButton, Notification } from "@common";

import { onlyNumbersRegex } from "@utils/const";
import { useSelector, useDispatch } from "react-redux";
import { ErrorMessages } from "@components/Auth/types";
import { useNavigate } from "react-router-dom";
import { confirmEmail } from "@redux/modules/auth";
import { changeEmail } from "@redux/modules/profile";
import { LoadingStatus, Paths, ConfirmErrorMessage } from "@types";

import classes from "./style.module.scss";

interface Props {
  setStepNumber: Dispatch<SetStateAction<number>>;
  email: string;
}

export const ConfirmEmail: React.FC<Props> = ({ setStepNumber, email }) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [code, setCode] = useState("");
  const key = localStorage.getItem("biodata_key") as string;
  const [seconds, setSeconds] = useState(59);
  const [startTimer, setStart] = useState(false);
  const [isCodeSend, setIsSend] = useState(false);
  const [notification, setNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");

  const confirmStatus = useSelector((state: RootState) => state.auth.confirmStatus);
  const confirmEmailMessage = useSelector((state: RootState) => state.auth.confirmEmailMessage);

  useEffect(() => {
    if (confirmStatus === LoadingStatus.Success) {
      navigate(Paths.Profile);
    }

    if (confirmStatus === LoadingStatus.Error) {
      if (confirmEmailMessage === ConfirmErrorMessage.AttemptsExhausted) {
        navigate(Paths.Root);
      } else {
        setNotification(true);
        setNotificationMessage(confirmEmailMessage);
      }
    }
  }, [confirmStatus, confirmEmailMessage]);

  const handleChangeCode = (e) => {
    const value = e.target.value;
    if (value.match(onlyNumbersRegex) || value === "") {
      setCode(e.target.value);
    }
  };

  const handleSendCode = () => {
    setStart(true);
    setIsSend(true);
    if (key) {
      dispatch(confirmEmail({ code, token: key }));
    }
  };

  const handleResendCode = () => {
    setStart(true);
    setSeconds(59);
    if (email) {
      dispatch(changeEmail({ email, token: key }));
    }
  };

  useEffect(() => {
    if (startTimer) {
      const timer = setTimeout(() => setSeconds(seconds - 1), 1000);
      if (seconds === 0) {
        setStart(false);
      }
      return () => {
        clearTimeout(timer);
      };
    }
  }, [startTimer, seconds]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <h2 className={classes.title}>Подтвердите адрес</h2>
        <p className={classes.description}>
          На адрес {email} отправлен четырёхзначный код активации – пожалуйста, введите его здесь чтобы подтвердить
          адрес электронной почты
        </p>
        <div className={classes.input}>
          <Textfield value={code} handleChange={handleChangeCode} className={classes.code} maxLength={4} />
        </div>
        <div className={classes.button}>
          <Button
            handleClick={handleSendCode}
            text={"Подтвердить"}
            disabled={!code.match(onlyNumbersRegex) || code.length !== 4}
          />
        </div>
        <div className={classes.button}>
          {isCodeSend && (
            <StrokeButton
              text={startTimer ? `Отправить повторно через ${seconds}` : "Отправить повторно"}
              handleClick={handleResendCode}
              disabled={startTimer}
            />
          )}
        </div>
      </div>
      <Notification
        title={ErrorMessages.Title}
        message={notificationMessage}
        isOpen={notification}
        icon={<AlertTwoTone twoToneColor="#eb2f96" />}
        onClose={() => setNotification(false)}
        duration={1}
      />
    </div>
  );
};
