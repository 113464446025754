import React, { useState, useEffect, SetStateAction, Dispatch } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Checkbox, Button } from "@common";
import { fetchGoals, editGoals } from "@redux/modules/auth/slice";
import { AppDispatch, RootState } from "@redux/store";
import { useNavigate } from "react-router-dom";
import { Paths } from "@types";
import { LoadingStatus } from "@types";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import classes from "./style.module.scss";

interface Props {
  setStepNumber: Dispatch<SetStateAction<number>>;
}

export const EnterGoals: React.FC<Props> = ({ setStepNumber }) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [goals, setGoals] = useState<any[]>([]);

  const token = useSelector(
    (state: RootState) => state.auth.user?.auth_key
  ) as string;

  const fetchGoalsStatus = useSelector(
    (state: RootState) => state.auth.fetchGoalsStatus
  );

  const fetchedGoals = useSelector((state: RootState) => state.auth.goals);
  const editGoalsStatus = useSelector(
    (state: RootState) => state.auth.editGoalsStatus
  );

  useEffect(() => {
    dispatch(fetchGoals(token));
  }, []);

  useEffect(() => {
    if (fetchGoalsStatus === LoadingStatus.Success) {
      setGoals(
        fetchedGoals.map((item) => {
          return {
            ...item,
            checked: false,
          };
        })
      );
    }
  }, [fetchGoalsStatus]);

  useEffect(() => {
    if (editGoalsStatus === LoadingStatus.Success) {
      setStepNumber(3)
    }
  }, [editGoalsStatus]);

  const handleChange = (e:CheckboxChangeEvent, keyId?: string) => {
    setGoals(
      goals.map((item) => {
        return item.uuid === keyId
          ? { ...item, checked: e.target.checked }
          : item;
      })
    );
  };

  const handleSendData = () => {
    const data: string[] = [];

    for (let i = 0; i < goals.length; i++) {
      if (goals[i].checked) {
        data.push(goals[i].uuid);
      }
    }
    dispatch(editGoals({ goals: data, token }));
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <h2 className={classes.title}>
          Как вы хотели бы использовать приложение?
        </h2>
        <p>
          Понимание ваших целей поможет нам создать наилучший план. Выберите
          минимум одну задачу
        </p>

        {goals &&
          goals.map((item: any) => {
            return (
              <div className={classes.goalBlock} key={item.uuid}>
                <span>{item.name}</span>
                <Checkbox
                  checked={item.checked}
                  handleChange={handleChange}
                  keyId={item.uuid}
                />
              </div>
            );
          })}
        <Button handleClick={handleSendData} text={"Следующий шаг"} />
      </div>
    </div>
  );
};
