import React from "react";

export const Apple = () => {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 21.417 21.417"
      width="21"
      height="21"
      xmlSpace="preserve"
    >
      <g>
        <g>
          <g>
            <path
              fill="#010002"
              d="M14.838,0h-0.566c-2.005,0.788-3.579,2.016-3.676,4.743C13.334,5.083,14.815,2.9,14.838,0z"
            />
            <path
              fill="#010002"
              d="M7.062,21.417h1.132c1.557-1.175,4.31-0.935,5.937,0h0.849c1.629-0.714,4.241-3.938,4.241-5.749
				c-3.355-0.684-3.744-7.138-0.566-8.338l0,0c-0.894-1.323-2.116-2.173-4.031-2.174l0,0c-0.159,0-0.323,0.006-0.492,0.019l0,0
				c-1.176,0.083-2.348,1.006-3.393,1.006l0,0c-1.171,0-2.159-0.964-3.252-1.006l0,0C-0.984,4.842,2.122,18.521,7.062,21.417z"
            />
          </g>
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
};
