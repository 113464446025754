import React from "react";

export const Facebook = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="12" fill="#404BB2" />
      <path
        d="M12.3034 19.02C12.5795 19.02 12.8034 18.7962 12.8034 18.52V13.0204C12.8034 12.7443 13.0272 12.5204 13.3034 12.5204H14.5446C14.7959 12.5204 15.0082 12.3338 15.0404 12.0844L15.2385 10.5507C15.2771 10.2516 15.0442 9.98663 14.7426 9.98663H13.3034C13.0272 9.98663 12.8034 9.76277 12.8034 9.48663V8.36919C12.8034 7.63584 13.0062 7.13606 14.059 7.13606L14.8998 7.13572C15.1759 7.1356 15.3996 6.91178 15.3996 6.63572V5.30845C15.3996 5.05722 15.2131 4.84674 14.9626 4.82753C14.59 4.79895 14.0451 4.77002 13.4457 4.77002C11.5115 4.77002 10.1874 5.95061 10.1874 8.11825V9.48663C10.1874 9.76278 9.96353 9.98663 9.68739 9.98663H8.5C8.22386 9.98663 8 10.2105 8 10.4866V12.0204C8 12.2965 8.22386 12.5204 8.5 12.5204H9.68739C9.96353 12.5204 10.1874 12.7443 10.1874 13.0204V18.52C10.1874 18.7962 10.4112 19.02 10.6874 19.02H12.3034Z"
        fill="white"
      />
    </svg>
  );
};
