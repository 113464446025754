export enum UserTypes {
  Admin = "Админ",
  Consultant = "Консультант",
  Client = "Клиент",
  Assistant = "Ассистент",
  MainConsultant = "Главный консультант",
  Bioinformatician = "Биоинформатик",
  Employee = "Сотрудник",
}

export const mapUserType = (type: number) => {
  switch (type) {
    case 0:
      return "";
    case 1:
      return UserTypes.Admin;
    case 2:
      return UserTypes.Consultant;
    case 3:
      return UserTypes.Client;
    case 4:
      return UserTypes.Assistant;
    case 5:
      return UserTypes.MainConsultant;
    case 6:
      return UserTypes.Bioinformatician;
    case 7:
      return UserTypes.Employee;
    default:
      return UserTypes.Employee;
  }
};
