import React from "react";
import { Paths } from "@types";
import { Button } from "@common";

import { useNavigate } from "react-router-dom";
import classes from "./style.module.scss";

export const AppleUserNotExistLayout = () => {
  const navigate = useNavigate();
  const handleGoToRegister = () => {
    navigate(Paths.Root);
  };
  return (
    <div className={classes.appleUserNoExist}>
      <div className={classes.container}>
        <h2 className={classes.title}>Такого пользователя не существует</h2>
        <p className={classes.description}>Пожалуйста, зарегистрируйтесь и попробуйте еще раз</p>
        <Button text="Зарегистрироваться" handleClick={handleGoToRegister} />
      </div>
    </div>
  );
};
