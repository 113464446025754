import axios from "axios";
import { Routes } from "../auth/types";
import { SetSocialBind, DeleteSocialBind } from "./types";

const baseUrl = process.env.REACT_APP_BASE_URL;

const checkSocialBindsAPI = (token: string) => {
  return axios.post(`${baseUrl}${Routes.CheckSocialBind}`, { userToken: token });
};

const setBindAPI = (data: SetSocialBind) => {
  return axios.post(`${baseUrl}${Routes.SetBind}`, { ...data });
};

const deleteBindAPI = (data: DeleteSocialBind) => {
  return axios.delete(`${baseUrl}${Routes.DeleteBind}`, {
    data,
    headers: { Authorization: `Bearer ${data.userToken}` },
  });
};

const getUserProfileAPI = (token: string) => {
  return axios.get(`${baseUrl}${Routes.UserProfile}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const saveUserProfileAPI = (data: any, token: string) => {
  return axios.put(
    `${baseUrl}${Routes.SaveUserProfile}`,
    { ...data },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const changeEmailAPI = (email: string, token: string) => {
  return axios.post(
    `${baseUrl}${Routes.ChangeEmail}`,
    { email: email },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const attachEmailAPI = (email: string, token: string) => {
  return axios.post(
    `${baseUrl}${Routes.AttachEmail}`,
    { email: email },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const uploadDemoAPI = (file: any, token: string) => {
  return axios.post(
    `${baseUrl}${Routes.Demo}`,
    { file: file, typeId: 100, comment: "", autoprovide: "1", phasesCycleId: "1" },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const createDemoProfileAPI = () => {
  return axios.post(`${baseUrl}${Routes.CreateDemoAccount}`);
};

export {
  checkSocialBindsAPI,
  setBindAPI,
  deleteBindAPI,
  getUserProfileAPI,
  saveUserProfileAPI,
  changeEmailAPI,
  attachEmailAPI,
  uploadDemoAPI,
  createDemoProfileAPI,
};
