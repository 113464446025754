import axios from "axios";
import {
  Routes,
  Login,
  Register,
  LoginSocial,
  SendEmailToConfirm,
  ConfirmEmailByCode,
  UserInfo,
  ApproveNewPassword,
} from "./types";

const baseUrl = process.env.REACT_APP_BASE_URL;

const loginAPI = (data: Login) => {
  return axios.post(`${baseUrl}${Routes.Login}`, { ...data });
};

const createSessionAPI = (token: string) => {
  return axios.post(
    `${baseUrl}${Routes.CreateSession}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const registerAPI = (data: Register) => {
  return axios.post(`${baseUrl}${Routes.Register}`, { ...data });
};

const sendEmailConfirmAPI = (data: SendEmailToConfirm) => {
  return axios.post(
    `${baseUrl}${Routes.SendEmailConfirmCode}`,
    {
      email: data.email,
    },
    {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    }
  );
};

const emailConfirmCodeAPI = (data: ConfirmEmailByCode) => {
  return axios.post(
    `${baseUrl}${Routes.EmailConfirm}`,
    { code: data.code },
    {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    }
  );
};

const editUserInfoAPI = (data: UserInfo, token: string) => {
  return axios.put(
    `${baseUrl}${Routes.EditUser}`,
    { ...data },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const fetchGoalsAPI = (token: string) => {
  return axios.get(`${baseUrl}${Routes.FetchGoals}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const loginSocialAPI = (data: LoginSocial) => {
  return axios.post(`${baseUrl}${Routes.LoginSocial}`, data);
};

const registerSocialAPI = (data: LoginSocial) => {
  return axios.post(`${baseUrl}${Routes.RegisterSocial}`, data);
};

const editGoalsAPI = (data: Array<string>, token: string) => {
  return axios.post(
    `${baseUrl}${Routes.EditGoals}`,
    { items: data },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const resetPasswordAPI = (email: string) => {
  return axios.post(`${baseUrl}${Routes.ResetPasswordRequest}`, { email });
};

const approveNewPasswordAPI = (data: ApproveNewPassword) => {
  return axios.post(`${baseUrl}${Routes.ResetPasswordApprove}`, { ...data });
};

const checkGoalsAlreadyExistAPI = (token: string) => {
  return axios.get(`${baseUrl}${Routes.CheckGoalsAlreadyExist}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export {
  loginAPI,
  registerAPI,
  loginSocialAPI,
  sendEmailConfirmAPI,
  emailConfirmCodeAPI,
  editUserInfoAPI,
  fetchGoalsAPI,
  editGoalsAPI,
  registerSocialAPI,
  resetPasswordAPI,
  approveNewPasswordAPI,
  createSessionAPI,
  checkGoalsAlreadyExistAPI
};
