import React from "react";
import { Goal } from "@assets/icons";
import classes from "./style.module.scss";

const Progress = ({ numm }) => {
  return (
    <div className={classes.progress}>
      <div className={classes.active} style={{ width: `${numm}%` }} />
    </div>
  );
};

export const Goals = () => {
  return (
    <div className={classes.goals}>
      <h3>Цели</h3>
      <div className={classes.goalItem}>
        <div className={classes.circle}>
          <Goal />
        </div>
        <div className={classes.goalContent}>
          <h4>Название цели</h4>
          <Progress numm={50} />
        </div>
      </div>

      <div className={classes.goalItem}>
        <div className={classes.circle}>
          <Goal />
        </div>
        <div className={classes.goalContent}>
          <h4>Название цели</h4>
          <Progress numm={50} />
        </div>
      </div>

      <div className={classes.goalItem}>
        <div className={classes.circle}>
          <Goal />
        </div>
        <div className={classes.goalContent}>
          <h4>Название цели</h4>
          <Progress numm={50} />
        </div>
      </div>
    </div>
  );
};
