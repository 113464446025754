import { Paths } from "@types";
import { getCookie } from "@utils/functions";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const Main = () => {
  const navigate = useNavigate();

  // useEffect(() => {
  //   const key = localStorage.getItem("biodata_key");
  //   if (key) {
  //     if (process.env.NODE_ENV === "development") {
  //       navigate(Paths.Profile);
  //     } else {
  //       window.location.href = `${process.env.REACT_APP_OLD}/profile/index`;
  //     }
  //   }
  // }, []);
  return <></>;
};
