import React, { useEffect, useState } from "react";
import { ConfirmEmail } from "@components/ChangeEmailSteps/ConfirmEmail";
import { ChangeEmail } from "@components/ChangeEmailSteps/ChangeEmail";
import { useLocation } from "react-router-dom";

export const ChangeEmailStepper = () => {
  const { state } = useLocation() as any;
  const [email, setEmail] = useState("");

  const [stepNumber, setStepNumber] = useState(0);

  switch (stepNumber) {
    case 0:
      return <ChangeEmail setStepNumber={setStepNumber} email={email} setEmail={setEmail} />;
    case 1:
      return <ConfirmEmail setStepNumber={setStepNumber} email={email} />;
  }
  return <ChangeEmail setStepNumber={setStepNumber} email={email} setEmail={setEmail} />;
};
