import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { AppDispatch, RootState } from "@redux/store";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LoadingStatus, Paths, SocialLoginTypes } from "@types";
import { deleteSocialBind } from "@redux/modules/profile";

export const AppleSuccessBindDelete = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get("data");
  const deleteBindStatus = useSelector((state: RootState) => state.profile.deleteSocialBindStatus);

  const userToken = localStorage.getItem("biodata_key") as string;

  useEffect(() => {
    if (token) {
      dispatch(
        deleteSocialBind({
          socialName: SocialLoginTypes.Apple,
          userToken: userToken,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (deleteBindStatus === LoadingStatus.Success || deleteBindStatus === LoadingStatus.Error) {
      navigate(Paths.Profile);
    }
  }, [deleteBindStatus]);

  return <></>;
};
