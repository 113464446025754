import {
  Calendar,
  Drug,
  Files,
  Form,
  Genetic,
  Graph,
  Home,
  Question,
  Search,
  Zipper,
  Subscription,
  Profile,
} from "@assets/icons";
import { SocialLoginTypes } from "@types";

export const demoMenu = [
  {
    id: 7,
    title: "Файлы",
    icon: Files,
    url: "/user-files/index",
  },
  {
    id: 2,
    title: "Задачи",
    icon: Zipper,
    url: "/risk-panel/index",
  },
  {
    id: 1,
    title: "Анализы",
    icon: Search,
    url: "/biomarkers/full-view",
  },
  {
    id: 3,
    title: "Профиль",
    icon: Profile,
    url: "/profile",
  },
];

export const menu = [
  {
    id: 0,
    title: "Главная",
    icon: Home,
    url: "/profile/index",
  },
  {
    id: 1,
    title: "Анализы",
    icon: Search,
    url: "/biomarkers/full-view",
  },
  {
    id: 2,
    title: "Задачи",
    icon: Zipper,
    url: "/risk-panel/index",
  },
  {
    id: 3,
    title: "Рекомендации",
    icon: Drug,
    url: "/recommendations/list",
  },
  {
    id: 4,
    title: "Дневник",
    icon: Calendar,
    url: "/diary/index",
  },
  {
    id: 5,
    title: "Генетика",
    icon: Genetic,
    url: "/genetics/risks-all",
  },
  {
    id: 6,
    title: "Анкета",
    icon: Form,
    url: "/poll/index",
  },
  {
    id: 7,
    title: "Файлы",
    icon: Files,
    url: "/user-files/index",
  },
  {
    id: 8,
    title: "Инфографика",
    icon: Graph,
    url: "/chart/index",
  },
  // {
  //   id: 9,
  //   title: "Подписка",
  //   icon: Subscription,
  //   url: "/personal/faq/",
  // },
];

export const initialBinds = {
  [SocialLoginTypes.Apple]: false,
  [SocialLoginTypes.Facebook]: false,
};

export interface IUserBinds {
  bindsStatus: any;
}

export enum ProfileFields {
  Name = "firstName",
  Surname = "lastName",
  BirthMonth = "month",
  BirthYear = "year",
  Gender = "gender",
  Email = "email",
  Type = "type",
}

export const initialProfile = {
  [ProfileFields.Name]: "",
  [ProfileFields.Surname]: "",
  [ProfileFields.BirthMonth]: "",
  [ProfileFields.BirthYear]: "",
  [ProfileFields.Gender]: 1,
  [ProfileFields.Email]: "",
  [ProfileFields.Type]: 0,
};

export enum GenderTypes {
  Male = "male",
  Female = "female",
}

export enum UserTypes {
  Admin = "Админ",
  Consultant = "Консультант",
  Client = "Клиент",
  Assistant = "Ассистент",
  MainConsultant = "Главный консультант",
  Bioinformatician = "Биоинформатик",
  Employee = "Сотрудник",
}
