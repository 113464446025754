import React from "react";

export const Sleep = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" fill="#7B53AD" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.4688 8.88495C21.0876 10.6728 18 14.9758 18 20C18 24.9753 21.0278 29.2434 25.3414 31.0622C23.9815 31.6002 22.4992 31.8958 20.9479 31.8958C14.3493 31.8958 9 26.5466 9 19.9479C9 13.3493 14.3493 8 20.9479 8C22.5478 8 24.0743 8.31446 25.4688 8.88495Z"
        fill="#FFF4E4"
      />
    </svg>
  );
};
